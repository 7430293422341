import { environment } from '@energy-stacks/feature-config';
import { createBaseQuery } from '@energy-stacks/shared';
import { createApi } from '@reduxjs/toolkit/query/react';
import { ObelisUserModel } from './obelisUserModel';
import { obelisUserModelNormalizer } from './normalizers/obelisUserModelNormalizer';

export const usersObelisApi = createApi({
  reducerPath: 'usersObelisApi',
  baseQuery: createBaseQuery(`${environment.ocppServiceUrl}/users`),
  endpoints: (builder) => ({
    getUser: builder.query<ObelisUserModel, void>({
      query: () => ({
        url: '/me',
      }),
      transformResponse: obelisUserModelNormalizer,
    }),
  }),
});

export const { useGetUserQuery } = usersObelisApi;
