import * as yup from 'yup';

export const createChargingStationRatingReportSchema = yup.object().shape({
  chargingHubUid: yup.string().trim(),
  evseId: yup.object({
    label: yup.string(),
    value: yup.string().required('evseIdRequired'),
  }),
  identityKey: yup.string().trim(),
  monthYear: yup.date().required('monthRequired'),
  rating: yup
    .number()
    .required('ratingRequired')
    .typeError('ratingMustBeANumber'),
  totalComplaints: yup
    .string()
    .test('isNumber', 'mustBeNumber', (value) => {
      if (!value) {
        return true;
      }
      return !isNaN(Number(value));
    })
    .test('isPositive', 'mustBePositiveValue', (value) => {
      if (!value) {
        return true;
      }

      return Number(value) >= 0;
    })
    .test('onlyDigits', 'mustBeWholeNumber', (value) => {
      if (!value) {
        return true;
      }

      return /^\d+$/.test(value);
    }),
});
