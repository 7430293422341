import { appendZToDateString } from '@energy-stacks/shared';
import { reportFacilityTypeDtoToModelMap } from '../../reportFacilityTypeDtoToModelMap';
import { ProcessedFacilityDamagesReportDto } from '../processedFacilityDamagesReportDto';
import { ProcessedFacilityDamagesReportModel } from '../processedFacilityDamagesReportModel';

export const processedFacilityDamagesReportsNormalizer = (
  processedFacilityDamagesReportDto: ProcessedFacilityDamagesReportDto
): ProcessedFacilityDamagesReportModel => {
  const { uid, facilityType, emergenceDate, fixDate, forecastDate } =
    processedFacilityDamagesReportDto;

  return {
    uid: uid ?? undefined,
    facilityType: reportFacilityTypeDtoToModelMap[facilityType],
    emergenceDate: appendZToDateString(emergenceDate),
    fixDate: fixDate && appendZToDateString(fixDate),
    forecastDate: forecastDate && appendZToDateString(forecastDate),
  };
};
