import { RawRenewalReportDto } from '../rawRenewalReportsDto';
import { ChargePointComponentDto, ReasonDto } from '../renewedComponentsDto';
import {
  ChargePointComponentModel,
  ReasonModel,
} from '../renewedComponentsModel';
import { RenewalReportModel } from '../renewalReportModel';

export const renewalReportNormalizer = (
  renewalProcessedReport: RawRenewalReportDto
): RenewalReportModel => {
  const {
    chargingHubUid,
    chargingPointUid,
    evseId,
    end,
    identityKey,
    renewalUid,
    renewedComponents,
    start,
    uid,
  } = renewalProcessedReport;

  return {
    chargingHubUid,
    chargingPointUid,
    evseId: evseId || null,
    end,
    identityKey,
    renewalUid,
    renewedComponents: renewedComponents
      ? renewedComponents.map((renewedComponent) => {
          return {
            chargePointComponent:
              chargePointComponentDtoToModelMap[
                renewedComponent.chargePointComponent
              ],
            reason: reasonDtoToModelMap[renewedComponent.reason],
          };
        })
      : [],
    start,
    uid,
  };
};

export const chargePointComponentDtoToModelMap: Record<
  ChargePointComponentDto,
  ChargePointComponentModel
> = {
  CABLE_COOLING_UNIT: 'cableCoolingUnit',
  NETWORKING_ELECTRONICS: 'networkingElectronics',
  POWER_MODULE: 'powerModule',
  PAYMENT_DEVICE: 'paymentDevice',
  VEHICLE_COMMUNICATION_UNIT: 'vehicleCommunactionUnit',
  BACKEND_COMMUNICATION_UNIT: 'backendCommunactionUnit',
  DISPLAY: 'display',
  DC_METER: 'dcMeter',
  AIR_FILTER: 'airFilter',
  OTHERS: 'others',
} as const;

export const reasonDtoToModelMap: Record<ReasonDto, ReasonModel> = {
  DAMAGE: 'damage',
  HARDWARE_FAULT: 'hardwareFault',
  SOFTWARE_FAULT: 'softwareFault',
  RETROFITTING: 'retrofitting',
  OTHERS: 'others',
} as const;
