/**
 * Start with two uppercase letters (country code)
 * Asterisk (*)
 * Three alphanumeric characters (spot operator ID)
 * Asterisk (*)
 * The letter "E"
 * Four or five alphanumeric characters (ID type)
 * Asterisk (*)
 * Arbitrary characters (power outlet ID)
 *
 * e.g. DE*EHT*EZWE4309*2D
 */
export const OBELIS_EVSE_ID_REGEX =
  /^[A-Z]{2}\*[0-9a-zA-Z]{3}\*E([0-9a-zA-Z]{4}|[0-9a-zA-Z]{5})\*\S+$/;
