import { Dispatch, ReactNode, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { IconChevronLeft } from '@tabler/icons-react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { ESDialogActionButton, ESDialogActions } from '@energy-stacks/core/ui';
import { useCloseDialogPrompt } from '@energy-stacks/shared';

interface ChargingStationReportContainerProps {
  title: string;
  children: ReactNode;
  isFormValid: boolean;
  isFormDirty: boolean;
  isLoading: boolean;
  activeStep: number;
  setActiveStep?: Dispatch<SetStateAction<number>>;
  isEditMode: boolean;
}

export const ChargingStationReportContainer = ({
  title,
  children,
  isFormValid,
  isFormDirty,
  isLoading,
  setActiveStep,
  isEditMode,
}: ChargingStationReportContainerProps) => {
  const { t } = useTranslation('chargingStationReports');

  const showExitConfirmation = useCloseDialogPrompt({
    shouldPrompt: isFormDirty,
    onClose: () => {
      setActiveStep && setActiveStep((prev) => prev - 1);
    },
  });

  return (
    <>
      <Card
        variant="outlined"
        sx={{ borderRadius: '12px', marginBottom: 6, overflowY: 'auto' }}
      >
        <CardContent
          sx={{
            padding: '0 !important',
          }}
        >
          <Box
            sx={{
              padding: '16px',
              backgroundColor: 'grey.100',
            }}
          >
            <Typography
              sx={{
                color: 'grey.900',
                fontSize: 14,
                fontWeight: 700,
                lineHeight: 'normal',
              }}
            >
              {t(title)}
            </Typography>
          </Box>
          {children}
        </CardContent>
      </Card>
      <ESDialogActions sx={{ p: 0 }}>
        <ESDialogActionButton
          loading={isLoading}
          variant="text"
          onClick={() => {
            showExitConfirmation();
          }}
          sx={{
            mr: 'auto',
            '&.MuiButton-outlined': {
              borderColor: 'primary.main',
              '&:hover': {
                backgroundColor: 'primary.light',
                borderColor: 'primary.main',
              },
            },
          }}
        >
          <IconChevronLeft style={{ marginRight: 12, height: 20 }} />
          {t('createReportStepBackButtonLabel')}
        </ESDialogActionButton>
        <ESDialogActionButton
          variant="contained"
          type="submit"
          disabled={!isFormValid || !isFormDirty}
          loading={isLoading}
        >
          {t(
            `${
              isEditMode
                ? `editReportStepActionButton`
                : `createReportStepActionButtonLabels.${1}`
            }`
          )}
        </ESDialogActionButton>
      </ESDialogActions>
    </>
  );
};
