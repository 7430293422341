import {
  IDENTITY_KEY_REGEX,
  LATITUDE_OCPI_REGEX,
  LATITUDE_REGEX,
  LONGITUDE_OCPI_REGEX,
  LONGITUDE_REGEX,
} from '@energy-stacks/shared';
import * as yup from 'yup';

export const addChargingStationValidationScheme = yup.object().shape({
  id: yup
    .string()
    .trim()
    .required('idRequired')
    .max(32, 'idTooLong')
    .matches(IDENTITY_KEY_REGEX, {
      message: 'idSpecialCharactersError',
      excludeEmptyString: true,
    }),
  name: yup.string().trim().required('nameRequired').max(255, 'nameTooLong'),
  chargingHub: yup.object().shape({
    label: yup.string().required(),
    value: yup.string().required('chargingHubRequired'),
  }),
  vendor: yup.string().trim().max(50, 'vendorTooLong'),
  model: yup.string().trim().max(20, 'modelTooLong'),
  coordinates: yup.object().shape({
    longitude: yup
      .string()
      .matches(LONGITUDE_REGEX, {
        message: 'longitudeValidation',
        excludeEmptyString: true,
      })
      .matches(LONGITUDE_OCPI_REGEX, {
        message: 'coordinateDecimals',
        excludeEmptyString: true,
      })
      .required('longitudeRequired')
      .when('latitude', {
        is: (value: string) => !!value,
        then: yup.string().required('coordinatesDependencyErrorMessage'),
        otherwise: yup.string().nullable(),
      }),
    latitude: yup.lazy(() =>
      yup
        .string()
        .matches(LATITUDE_REGEX, {
          message: 'latitudeValidation',
          excludeEmptyString: true,
        })
        .matches(LATITUDE_OCPI_REGEX, {
          message: 'coordinateDecimals',
          excludeEmptyString: true,
        })
        .required('latitudeRequired')
        .when('longitude', {
          is: (value: string) => !!value,
          then: yup.string().required('coordinatesDependencyErrorMessage'),
          otherwise: yup.string().nullable(),
        })
    ),
  }),
});
