import { useAppDispatch } from '@energy-stacks/store';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import {
  ESTextField,
  ESVirtualizedAutocomplete,
  useESSnackbar,
} from '@energy-stacks/core/ui';
import {
  chargingStationReportsApi,
  chargingStationsReportsApiErrors,
  reportRatingModel,
  ReportRatingModel,
  useCreateProcessedRatingReportMutation,
  useEditProcessedRatingReportMutation,
} from '@energy-stacks/obelis/feature-charging-station-reports-data';
import { editChargingStationRatingReportSchema } from './editChargingStationRatingReportSchema';
import { createChargingStationRatingReportSchema } from './createChargingStationRatingReportSchema';
import { useProcessedRatingReport } from './useProcessedRatingReport';
import { useRawRatingReports } from './useRawRatingReports';
import {
  ErrorApiResponse,
  formatMonthYearToPayload,
  RefetchOnError,
} from '@energy-stacks/shared';
import {
  getReportDefaultDate,
  MonthYearPicker,
} from '@energy-stacks/obelis/shared';
import { Box } from '@mui/material';
import { ChargingStationReportContainer } from '../ChargingStationReportContainer';
import { endOfDay, lastDayOfMonth, subMonths } from 'date-fns';
import { ChargingStationReportContainerHeader } from '../ChargingStationReportContainerHeader';
import { useChargingStationDetails } from '../useChargingStationDetails';

export interface RatingReportFormData {
  monthYear: Date;
  rating: ReportRatingModel | string;
  totalComplaints: string | null;
  evseId: string;
}

const lastMonth = endOfDay(lastDayOfMonth(subMonths(new Date(), 1)));

export const ChargingStationRatingReportForm = ({
  setIsRatingFormDirty,
  setIsDisabled,
  activeStep,
  setActiveStep,
  isEditMode,
}: {
  setIsRatingFormDirty: (isRatingFormDirty: boolean) => void;
  setIsDisabled: (isDisabled: boolean) => void;
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
  isEditMode: boolean;
}) => {
  const { t } = useTranslation('chargingStationReports');
  const navigate = useNavigate();
  const { id, uid, selectedMonth } = useParams();
  const { showSnackbar } = useESSnackbar();
  const [createProcessedRatingReport, { isLoading: isCreating }] =
    useCreateProcessedRatingReportMutation();
  const [editProcessedRatingReport, { isLoading: isEditing }] =
    useEditProcessedRatingReportMutation();
  const dispatch = useAppDispatch();
  const {
    rawReports,
    isLoading: isRawReportsLoading,
    error: rawDataError,
  } = useRawRatingReports(isEditMode);
  const {
    processedReport,
    isLoading: isProcessedReportLoading,
    error: processedDataError,
  } = useProcessedRatingReport(isEditMode);
  const tableValueHeader = isEditMode ? 'currentValue' : 'originalValue';

  const reportData = isEditMode ? processedReport : rawReports?.[0];

  const {
    chargingStationDetails,
    isError,
    isLoading: isDetailsLoading,
    refetch,
  } = useChargingStationDetails(isEditMode);

  const chargingPointEvsesOptions = chargingStationDetails?.evses?.map(
    (evse) => {
      return {
        label: evse.evseId,
        value: evse.evseId,
      };
    }
  );

  const defaultValues = useMemo(() => {
    return {
      evseId: reportData?.evseId || '',
      monthYear: getReportDefaultDate(
        reportData?.monthYear,
        selectedMonth,
        lastMonth
      ),
      rating: reportData?.rating || '',
      totalComplaints: reportData?.totalComplaints || '',
    };
  }, [reportData, selectedMonth]);

  const validationSchema = isEditMode
    ? editChargingStationRatingReportSchema
    : createChargingStationRatingReportSchema;

  const formMethods = useForm<RatingReportFormData>({
    defaultValues,
    mode: 'onTouched',
    resolver: yupResolver(validationSchema),
  });

  const {
    handleSubmit,
    formState: { isValid: isFormValid, isDirty: isFormDirty, errors },
    reset: resetForm,
    control,
  } = formMethods;

  const handleClose = useCallback(() => {
    resetForm();
    navigate(-1);
  }, [navigate, resetForm]);

  const isDisabled =
    isCreating ||
    isEditing ||
    isDetailsLoading ||
    isRawReportsLoading ||
    isProcessedReportLoading;

  useEffect(() => {
    setIsRatingFormDirty(isFormDirty);
    setIsDisabled(isDisabled);
  }, [isFormDirty, setIsRatingFormDirty, setIsDisabled, isDisabled]);

  const onSubmit: SubmitHandler<RatingReportFormData> = (data) => {
    const evse = chargingStationDetails?.evses.find((evse) => {
      return evse.evseId === data.evseId;
    });

    const chargingPointUidPayload = isEditMode
      ? reportData?.chargingPointUid
      : evse?.chargingPointId;

    const chargingHubId =
      reportData?.chargingHubUid || chargingStationDetails?.chargingHub.id;

    if (!chargingPointUidPayload || !id || !chargingHubId) {
      return;
    }

    const createBody = {
      chargingHubUid: chargingHubId,
      chargingPointUid: chargingPointUidPayload,
      identityKey: id,
      monthYear: formatMonthYearToPayload(data.monthYear),
      rating: data.rating,
      totalComplaints: data.totalComplaints
        ? Number(data.totalComplaints)
        : null,
    };
    const editBody = {
      monthYear: formatMonthYearToPayload(data.monthYear),
      rating: data.rating,
      totalComplaints: data.totalComplaints
        ? Number(data.totalComplaints)
        : null,
    };
    const successMessage = isEditMode
      ? 'editChargingStationRatingSuccess'
      : 'addChargingStationRatingSuccess';
    const reportHandler = isEditMode
      ? editProcessedRatingReport({ body: editBody, uid: uid ?? '' })
      : createProcessedRatingReport(createBody);

    reportHandler
      .unwrap()
      .then(() => {
        showSnackbar('success', successMessage, 'chargingStationReports');
        dispatch(
          chargingStationReportsApi.util.invalidateTags([
            'ChargingStationReports',
          ])
        );
        handleClose();
      })
      .catch((error) => {
        showSnackbar(
          'error',
          chargingStationsReportsApiErrors[error.data?.errorCode],
          'chargingStationReports'
        );
      });
  };

  useEffect(() => {
    if (rawDataError) {
      if ('status' in rawDataError && rawDataError.status === 404) {
        return;
      } else {
        showSnackbar(
          'error',
          'rawReportFetchErrorMessage',
          'chargingStationReports'
        );
      }
    }
  }, [rawDataError, showSnackbar]);

  useEffect(() => {
    if (processedDataError) {
      if ('data' in processedDataError) {
        const response = processedDataError.data as ErrorApiResponse;
        processedDataError.data
          ? showSnackbar(
              'error',
              chargingStationsReportsApiErrors[response.errorCode],
              'chargingStationReports'
            )
          : showSnackbar(
              'error',
              'processedReportFetchErrorMessage',
              'chargingStationReports'
            );
      } else {
        showSnackbar(
          'error',
          'processedReportFetchErrorMessage',
          'chargingStationReports'
        );
      }
      handleClose();
    }
  }, [processedDataError, showSnackbar, handleClose]);

  useEffect(() => {
    resetForm(defaultValues);
  }, [defaultValues, resetForm]);

  if (isError) {
    return (
      <Box sx={{ mt: 50 }}>
        <RefetchOnError onRefetch={refetch} />
      </Box>
    );
  }

  return (
    <FormProvider {...formMethods}>
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <ChargingStationReportContainer
          title="rating"
          isFormValid={isFormValid}
          isFormDirty={isFormDirty}
          isLoading={isCreating || isEditing}
          activeStep={activeStep ?? 1}
          setActiveStep={setActiveStep}
          isEditMode={isEditMode}
        >
          {!isEditMode ? (
            <Grid
              container
              sx={{
                padding: 4,
                paddingTop: 4,
                paddingBottom: 4,
                alignItems: 'center',
              }}
            >
              <Grid item xs={4} mr={6}>
                <Controller
                  control={control}
                  name="evseId"
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value } }) => {
                    return (
                      <ESVirtualizedAutocomplete
                        label={t('evseID')}
                        disabled={isDisabled}
                        required
                        value={{
                          label: value ?? '',
                          value: value ?? '',
                        }}
                        options={chargingPointEvsesOptions || []}
                        onBlur={onBlur}
                        blurOnSelect
                        onChange={(_, value) => {
                          if (Array.isArray(value)) {
                            return;
                          }
                          if (value) {
                            onChange(value.value);
                          }
                        }}
                        noOptionsText={t('noEvses')}
                        error={Boolean(errors['evseId'])}
                        helperText={
                          Boolean(errors['evseId']) &&
                          t(`${errors['evseId']?.message}`)
                        }
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          ) : null}
          <ChargingStationReportContainerHeader
            tableValueHeader={tableValueHeader}
          />
          <Grid
            container
            spacing={8}
            sx={{
              padding: 4,
              paddingTop: 4,
              paddingBottom: 4,
              alignItems: 'center',
            }}
          >
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight={500} color={'grey.600'}>
                {t('month') + ' *'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1">
                {reportData?.monthYear ?? '-'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="monthYear"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <MonthYearPicker
                      value={value}
                      onChange={onChange}
                      maxDate={lastMonth}
                    />
                  );
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1" fontWeight={500} color={'grey.600'}>
                {t('rating') + ' *'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1">
                {reportData?.rating ?? '-'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="rating"
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <ESVirtualizedAutocomplete
                      label=""
                      required
                      disabled={isDisabled}
                      value={{
                        label: value ?? '',
                        value: value ?? '',
                      }}
                      options={reportRatingModel.map((curr) => ({
                        label: curr,
                        value: curr,
                      }))}
                      noOptionsText={t('noRatingOptions')}
                      onBlur={onBlur}
                      blurOnSelect
                      onChange={(_, value) => {
                        if (Array.isArray(value)) {
                          return;
                        }
                        if (value) {
                          onChange(value.value ?? '');
                        }
                      }}
                      error={Boolean(errors['rating'])}
                      helperText={
                        Boolean(errors['rating']) &&
                        t(`${errors['rating']?.message}`)
                      }
                      placeholder={
                        reportData?.rating ? reportData?.rating : '-'
                      }
                    />
                  );
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1" fontWeight={500} color={'grey.600'}>
                {t('totalComplaints')}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1">
                {reportData?.totalComplaints ?? '-'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="totalComplaints"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <ESTextField
                      value={value?.toString()}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={Boolean(errors['totalComplaints'])}
                      helperText={
                        Boolean(errors['totalComplaints']) &&
                        t(`${errors['totalComplaints']?.message}`)
                      }
                      disabled={isDisabled}
                      placeholder={
                        reportData?.totalComplaints
                          ? reportData?.totalComplaints
                          : '-'
                      }
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </ChargingStationReportContainer>
      </form>
    </FormProvider>
  );
};
