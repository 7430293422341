import { FC, useState } from 'react';
import { IconButton, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { addMonths, subMonths } from 'date-fns';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { formatMonthYear } from '@energy-stacks/shared';

const MIN_DATE = new Date(2024, 0, 1);
const MAX_DATE = new Date();

type MonthYearPickerProps = {
  value: Date;
  onChange: (newValue: Date) => void;
  minDate?: Date;
  maxDate?: Date;
};

export const MonthYearPicker: FC<MonthYearPickerProps> = ({
  value,
  onChange,
  minDate = MIN_DATE,
  maxDate = MAX_DATE,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const prevMonth = subMonths(value, 1);
  const nextMonth = addMonths(value, 1);

  return (
    <DatePicker
      openTo="month"
      views={['month', 'year']}
      value={value}
      onChange={(value) => value && onChange(value)}
      open={isOpen}
      onClose={() => setIsOpen(false)}
      minDate={minDate}
      maxDate={maxDate}
      renderInput={(params) => (
        <TextField
          value={formatMonthYear(value)}
          onClick={() => setIsOpen(true)}
          {...params}
          onKeyDown={(e) => e.preventDefault()}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <IconButton
                disabled={prevMonth.getMonth() < minDate.getMonth()}
                onClick={(e) => {
                  e.stopPropagation();
                  onChange(prevMonth);
                }}
              >
                <IconChevronLeft stroke={1.5} />
              </IconButton>
            ),
            endAdornment: (
              <IconButton
                disabled={nextMonth.getMonth() > maxDate.getMonth()}
                onClick={(e) => {
                  e.stopPropagation();
                  onChange(nextMonth);
                }}
              >
                <IconChevronRight stroke={1.5} />
              </IconButton>
            ),
          }}
          sx={({ spacing }) => ({
            input: {
              cursor: 'pointer',
              textAlign: 'center',
              fontWeight: 400,
              fontSize: spacing(3.5),
              lineHeight: spacing(5),
              textTransform: 'uppercase',
              padding: 3.5,
              caretColor: 'transparent',
            },
            '& .MuiOutlinedInput-root': {
              cursor: 'pointer',
            },
          })}
        />
      )}
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [8, 0],
            },
          },
        ],
      }}
    />
  );
};
