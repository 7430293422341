import { Dispatch, ReactNode, SetStateAction } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconChevronLeft } from '@tabler/icons-react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { ESDialogActionButton, ESDialogActions } from '@energy-stacks/core/ui';
import { useCloseDialogPrompt } from '@energy-stacks/shared';

interface SystemReportContainerProps {
  title: string;
  children: ReactNode;
  isFormValid: boolean;
  isFormDirty: boolean;
  isLoading: boolean;
  activeStep: number;
  setActiveStep?: Dispatch<SetStateAction<number>>;
  isEditMode?: boolean;
}

export const SystemReportContainer = ({
  title,
  children,
  isFormValid,
  isFormDirty,
  isLoading,
  setActiveStep,
  isEditMode,
}: SystemReportContainerProps) => {
  const { t } = useTranslation('systemReports');
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const isDisabled = !isFormValid || (isEditMode && !isFormDirty);
  const tableValueHeader = isEditMode ? 'currentValue' : 'originalValue';

  const showExitConfirmation = useCloseDialogPrompt({
    shouldPrompt: isFormDirty,
    onClose: () => {
      if (isEditMode) {
        navigate(`/system-reports/${id}/reports`);
      } else {
        setActiveStep && setActiveStep((prev) => prev - 1);
      }
    },
  });

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          borderRadius: '12px',
          marginBottom: 6,
          overflow: 'auto',
        }}
      >
        <CardContent
          sx={{
            padding: '0 !important',
          }}
        >
          <Grid container direction="column">
            <Grid
              item
              xs={12}
              sx={{
                padding: '16px',
                backgroundColor: 'grey.100',
              }}
            >
              <Typography
                sx={{
                  color: 'grey.900',
                  fontSize: 14,
                  fontWeight: 700,
                  lineHeight: 'normal',
                  textTransform: 'capitalize',
                }}
              >
                {title}
              </Typography>
            </Grid>
            <Grid
              container
              spacing={8}
              sx={{
                padding: '16px',
                alignItems: 'center',
                borderBottom: '1px solid',
                borderColor: 'grey.100',
              }}
            >
              <Grid item xs={4}>
                <Typography variant="subtitle1" fontWeight={700}>
                  {t('key')}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1" fontWeight={700}>
                  {t(tableValueHeader)}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1" fontWeight={700}>
                  {t('newValue')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {children}
        </CardContent>
      </Card>
      <ESDialogActions sx={{ p: 0 }}>
        <ESDialogActionButton
          loading={isLoading}
          variant="text"
          onClick={() => {
            showExitConfirmation();
          }}
          sx={{
            mr: 'auto',
            '&.MuiButton-outlined': {
              borderColor: 'primary.main',
              '&:hover': {
                backgroundColor: 'primary.light',
                borderColor: 'primary.main',
              },
            },
          }}
        >
          <IconChevronLeft style={{ marginRight: 12, height: 20 }} />
          {t('createReportStepBackButtonLabel')}
        </ESDialogActionButton>
        <ESDialogActionButton
          variant="contained"
          type="submit"
          disabled={isDisabled}
          loading={isLoading}
        >
          {t(
            `${
              isEditMode
                ? `editReportStepActionButton`
                : `createReportStepActionButtonLabels.${1}`
            }`
          )}
        </ESDialogActionButton>
      </ESDialogActions>
    </>
  );
};
