export const systemProcessedReportsApiErrors: Record<string, string> = {
  MONTH_ON_REPORT_NOT_VALID: 'monthOnReportNotValid',
  HOTLINE_RATING_REPORT_ALREADY_EXISTS: 'hotlineRatingReportAlreadyExists',
  HOTLINE_RATING_REPORT_UID_NOT_FOUND: 'hotlineRatingReportUidNotFound',
  NON_DISCRIMINATORY_ACCESS_REPORT_NOT_FOUND:
    'nonDiscriminatoryAccessReportNotFound',
  PROVIDER_GROUP_DO_NOT_START_WITH_2_CAPITAL_LETTERS:
    'providerGroupDoNotStartWith2CapitalLetters',
  PROVIDER_GROUP_CHANGED: 'providerGroupChanged',
  VALID_TO_DATE_IS_BEFORE_OR_EQUAL_TO_VALID_FROM:
    'validToDateIsBeforeOrEqualToValidFrom',
  NON_DISCRIMINATORY_ACCESS_REPORT_ALREADY_EXISTS:
    'nonDiscriminatoryAccessReportAlreadyExists',
  NON_DISCRIMINATORY_ACCESS_REPORT_WITH_UID_ALREADY_EXISTS:
    'nonDiscriminatoryAccessReportWithUidAlreadyExist',
  OBELIS_EXTERNAL_API_REQUEST_FAILED_429: 'awsRateLimitExceeded',
};
