import { appendZToDateString } from '@energy-stacks/shared';
import { additionalCostsReportDtoToModelNormalizer } from '../../normalizers/additionalCostsReportToPayload';
import { AdHocTariffRawReportDto } from '../adHocTariffRawReportDto';
import { AdHocTariffRawReportModel } from '../adHocTariffRawReportModel';

export const adHocTariffRawReportNormalizer = (
  addHocTariffRawReportDto: AdHocTariffRawReportDto
): AdHocTariffRawReportModel => {
  const {
    uid,
    monthYear,
    chargingHubUid,
    cost,
    additionalCosts,
    validFrom,
    validTo,
  } = addHocTariffRawReportDto;

  return {
    uid: uid ?? undefined,
    monthYear: monthYear ?? '',
    chargingHubUid: chargingHubUid ?? '',
    cost: cost !== null ? String(cost) : '',
    additionalCosts: additionalCostsReportDtoToModelNormalizer(
      additionalCosts ?? []
    ),
    validFrom: validFrom ? appendZToDateString(validFrom) : undefined,
    validTo: validTo ? appendZToDateString(validTo) : undefined,
  };
};
