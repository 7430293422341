export const defaultRenewedComponents = {
  chargePointComponent: {
    label: '',
    value: '',
  },
  reason: {
    label: '',
    value: '',
  },
};
