import { OBELIS_EVSE_ID_REGEX } from '@energy-stacks/obelis/shared';
import * as yup from 'yup';
import { connectorValidationScheme } from '../connectorValidationScheme';

export const editEvseValidationScheme = yup.object().shape({
  evseId: yup
    .string()
    .max(48, 'evseIdTooLong')
    .matches(OBELIS_EVSE_ID_REGEX, {
      message: 'evseIdFormat',
      excludeEmptyString: true,
    })
    .required('fieldIsRequired'),
  capabilities: yup.array().of(yup.string()).min(1),
  connectors: yup.array().of(connectorValidationScheme).min(1),
});
