import { Box, Grid, IconButton, Typography } from '@mui/material';
import { IconX } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import {
  Controller,
  FieldArrayWithId,
  UseFieldArrayRemove,
  useFormContext,
} from 'react-hook-form';
import { ESTextField } from '@energy-stacks/core/ui';
import { ProcessedNonDiscriminatoryAccessReportFormData } from './SystemNonDiscriminatoryAccessReportForm';

interface AdditionalCostProps {
  additionalCostIndex: number;
  onRemove: UseFieldArrayRemove;
  isDisabled: boolean;
  additionalCost: FieldArrayWithId<
    ProcessedNonDiscriminatoryAccessReportFormData,
    'additionalCosts',
    'id'
  >;
}

export const AdditionalCost: React.FC<AdditionalCostProps> = ({
  additionalCostIndex,
  onRemove,
  isDisabled,
  additionalCost,
}) => {
  const { t } = useTranslation('systemReports');
  const {
    control,
    formState: { errors: formErrors },
  } = useFormContext();

  const errors = formErrors['additionalCosts']?.[additionalCostIndex];

  return (
    <Box
      sx={{
        pb: 6,
        pl: 6,
        pr: 2,
        pt: 1,
        backgroundColor: 'grey.100',
        borderRadius: (theme) => theme.spacing(3),
        mb: 3,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <IconButton
          disableRipple
          disabled={isDisabled}
          size="small"
          edge="end"
          onClick={() => onRemove(additionalCostIndex)}
          sx={{
            padding: 0.2,
            color: 'grey.500',
          }}
        >
          <IconX stroke={1.5} />
        </IconButton>
      </Box>
      <Grid
        container
        spacing={8}
        sx={{
          alignItems: 'center',
          pr: 4,
        }}
      >
        <Grid item xs={4}>
          <Typography variant="body1" fontWeight={500} color={'grey.600'}>
            {t('additionalCostDescription') + ' *'}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="body1" fontWeight={500} color={'grey.600'}>
            {additionalCost.description && !additionalCost.isNew
              ? additionalCost.description
              : '-'}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Controller
            name={`additionalCosts.${additionalCostIndex}.description`}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <ESTextField
                placeholder={
                  additionalCost.description && !additionalCost.isNew
                    ? additionalCost.description
                    : '-'
                }
                disabled={isDisabled}
                required
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                error={Boolean(errors?.description)}
                helperText={
                  Boolean(errors?.description) &&
                  errors?.description?.message &&
                  t(errors?.description?.message)
                }
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography variant="body1" fontWeight={500} color={'grey.600'}>
            {`${t('additionalCost')} [${t('ctPerHourWithoutVat')}] *`}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="body1" fontWeight={500} color={'grey.600'}>
            {additionalCost.cost && !additionalCost.isNew
              ? additionalCost.cost
              : '-'}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Controller
            name={`additionalCosts.${additionalCostIndex}.cost`}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <ESTextField
                placeholder={
                  additionalCost.cost && !additionalCost.isNew
                    ? additionalCost.cost
                    : '-'
                }
                value={value}
                disabled={isDisabled}
                onChange={(event) => {
                  const inputValue = event?.target.value;
                  // this regex matches a string that consists entirely of one or more digits, with no other characters before or after
                  if (inputValue === '' || /^\d+$/.test(inputValue ?? '')) {
                    onChange(inputValue);
                  }
                }}
                onBlur={onBlur}
                error={Boolean(errors?.cost)}
                helperText={
                  Boolean(errors?.cost) &&
                  errors?.cost?.message &&
                  t(`${errors?.cost?.message}`)
                }
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
