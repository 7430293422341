import { appendZToDateString } from '@energy-stacks/shared';
import { convertReportRatingDtoToModel } from '../../normalizers/reportRatingNormalizer';
import { ProcessedRatingReportDto } from '../processedRatingReportDto';
import { ProcessedRatingReportModel } from '../processedRatingReportModel';
import { ProcessedReportDto } from '../processedReportDto';
import { ProcessedReport } from '../processedReportModel';
import { ProcessedReportNameDto } from '../processedReportNameDto';
import { ProcessedReportTypeModel } from '../processedReportTypeModel';

export const processedRatingReportsNormalizer = (
  processedRatingReportDto: ProcessedRatingReportDto
): ProcessedRatingReportModel => {
  const { uid, monthYear, chargingHubUid, rating } = processedRatingReportDto;

  return {
    uid,
    monthYear,
    chargingHubUid,
    rating: convertReportRatingDtoToModel(rating),
  };
};

export const processedReportsNormalizer = (
  processedReportsDto: ProcessedReportDto[]
): ProcessedReport[] => {
  return processedReportsDto.map((processedReportDto) => {
    const {
      reportName,
      reportDate,
      reportedBy,
      modifiedBy,
      uid,
      lastModifiedDate,
    } = processedReportDto;

    return {
      reportType: processedReportNameDtoToTypeModelMap[reportName],
      reportDate: appendZToDateString(reportDate),
      reportedBy,
      modifiedBy,
      uid,
      lastModifiedDate: appendZToDateString(lastModifiedDate),
    };
  });
};

const processedReportNameDtoToTypeModelMap: Record<
  ProcessedReportNameDto,
  ProcessedReportTypeModel
> = {
  AD_HOC_TARIFF: 'adHocTariff',
  FACILITY_DAMAGES: 'facilityDamages',
  RATING: 'rating',
} as const;
