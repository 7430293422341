import {
  ESProfileSection,
  ESProfileSectionListItem,
  ESRoutes,
} from '@energy-stacks/shared';
import { useAppSelector } from '@energy-stacks/obelis/store';
import { IconLogout } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { useOidc } from '@axa-fr/react-oidc';
import { useGetUserQuery } from '@energy-stacks/obelis/feature-users-data';

export const ObelisProfileSection = () => {
  const { logout } = useOidc();
  const themeMode = useAppSelector((state) => state.theme.themeState);
  const sidebarOpened = useAppSelector((state) => state.sidebar.sidebarOpened);
  const { data: user } = useGetUserQuery();
  const [tShared] = useTranslation('shared');

  return (
    <ESProfileSection
      userName={user?.displayName || ''}
      emailAddress={user?.emailAddress || ''}
      themeMode={themeMode}
      collapsed={!sidebarOpened}
    >
      <ESProfileSectionListItem
        listItemText={tShared('logout')}
        testId="popupLogoutListItem"
        onClick={() => {
          logout(ESRoutes.Root);
        }}
        Icon={<IconLogout stroke={1.5} size={20} />}
      />
    </ESProfileSection>
  );
};
