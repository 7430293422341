import {
  ReasonModel,
  reasonsModel,
} from '@energy-stacks/obelis/feature-charging-sessions-data';
import { TableFilterButton } from '@energy-stacks/shared';
import { Box, MenuItem, Radio, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ChargingSessionsTableReasonFilterProps {
  reasonFilter: ReasonModel | undefined;
  onSetReasonFilter: (reasonFilter: ReasonModel | undefined) => void;
  testId?: string;
}

export const ChargingSessionsTableReasonFilter: React.FC<
  ChargingSessionsTableReasonFilterProps
> = ({ testId, onSetReasonFilter, reasonFilter }) => {
  const { t } = useTranslation('chargingSessions');

  const reasons = reasonsModel.map((reason) => {
    return {
      label: t(reason),
      value: reason,
    };
  });

  return (
    <TableFilterButton
      testId={testId}
      title={t('reason')}
      renderPopover={(closePopover) => {
        return (
          <Box
            sx={{
              maxHeight: 450,
              minWidth: 176,
            }}
          >
            <Box>
              {reasons.map((reason) => {
                return (
                  <MenuItem
                    data-testid={`${testId}MenuOption${reason.label}`}
                    dense
                    selected={reason.value === reasonFilter}
                    key={reason.value}
                    onClick={() => {
                      onSetReasonFilter(reason.value as ReasonModel);
                      closePopover();
                    }}
                  >
                    <Radio
                      checked={reason.value === reasonFilter}
                      value={reason}
                    />
                    <Typography>{reason.label}</Typography>
                  </MenuItem>
                );
              })}
            </Box>
          </Box>
        );
      }}
      isActive={Boolean(reasonFilter)}
    />
  );
};
