import {
  Main,
  NotFound,
  ESRoutes,
  ESErrorBoundary,
  useSetFavicon,
  useLoadGoogleMapsApiScript,
} from '@energy-stacks/shared';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ESOidcProvider, AccessDeniedPage } from '@energy-stacks/user-access';
import { ESAppProvider } from '@energy-stacks/feature-app-provider';
import { Sidebar } from '@energy-stacks/core/sidebar';
// NOTE: Do not remove this import! It loads react-i18next type definitions
import { i18nextInitialization } from '../i18n/i18n';
import { oidcConfiguration } from '../oidcConfiguration';
import {
  AddChargingHub,
  ChargingHubDetailsSlider,
  ChargingHubsDrawerItem,
  ChargingHubsPage,
  EditChargingHub,
} from '@energy-stacks/obelis/feature-charging-hubs';
import { ObelisLogoOverlay, ObelisRoutes } from '@energy-stacks/obelis/shared';
import { DarkLogo } from '@energy-stacks/theme';
import {
  ObelisProfileSection,
  ObelisUserLogin,
} from '@energy-stacks/obelis/feature-users';
import { useAppSelector } from '@energy-stacks/obelis/store';
import { environment } from '@energy-stacks/feature-config';
import {
  ChargingSessionsDrawerItem,
  ChargingSessionsPage,
} from '@energy-stacks/obelis/feature-charging-sessions';
import {
  ChargingStationsPage,
  ChargingStationsDrawerItem,
  ChargingStationDetailsSlider,
  AddChargingStation,
  EditChargingStation,
} from '@energy-stacks/obelis/feature-charging-stations';
import { ChargingStationReportDialog } from '@energy-stacks/obelis/feature-charging-station-reports';
import {
  SystemReportDialog,
  SystemReportsDrawerItem,
  SystemReportsPage,
} from '@energy-stacks/obelis/feature-system-reports';
import { ChargingHubReportDialog } from '@energy-stacks/obelis/feature-charging-hub-reports';
import { Divider } from '@mui/material';

// NOTE: This is here just to use i18next..init({...})
// or else the bundler will ignore it.x
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const i18nextInit = i18nextInitialization;

export const App = () => {
  useSetFavicon();

  return (
    <ESErrorBoundary>
      <ESAppProvider>
        <ESOidcProvider
          configuration={oidcConfiguration}
          LoadingComponent={ObelisLogoOverlay}
        >
          <ObelisUserLogin />
          <Routes>
            <Route path={ESRoutes.Root} element={<AppShell />}>
              <Route
                index
                element={<Navigate to={ObelisRoutes.ChargingHubs} replace />}
              />
              <Route
                path={ObelisRoutes.ChargingHubs}
                element={<ChargingHubsPage />}
              >
                <Route
                  path={ObelisRoutes.EditChargingHub}
                  element={<EditChargingHub />}
                />
                <Route
                  path={ObelisRoutes.AddChargingHub}
                  element={<AddChargingHub />}
                />
                <Route
                  path={`${ObelisRoutes.ChargingHubDetails}/*`}
                  element={<ChargingHubDetailsSlider />}
                >
                  <Route
                    path={`${ObelisRoutes.ChargingHubDetailsReportsTab}/${ObelisRoutes.CreateReport}/:selectedMonth`}
                    element={<ChargingHubReportDialog />}
                  />
                  <Route
                    path={`${ObelisRoutes.ChargingHubDetailsReportsTab}/${ObelisRoutes.EditReport}`}
                    element={<ChargingHubReportDialog />}
                  />
                </Route>
              </Route>
              <Route
                path={ObelisRoutes.ChargingStations}
                element={<ChargingStationsPage />}
              >
                <Route
                  path={`${ObelisRoutes.EditChargingStationGeneralDetails}`}
                  element={<EditChargingStation />}
                />
                <Route
                  path={ObelisRoutes.AddChargingStation}
                  element={<AddChargingStation />}
                />
                <Route
                  path={`${ObelisRoutes.ChargingStationDetails}/*`}
                  element={<ChargingStationDetailsSlider />}
                >
                  <Route
                    path={`${ObelisRoutes.ChargingStationDetailsReportsTab}/${ObelisRoutes.CreateReport}/:selectedMonth`}
                    element={<ChargingStationReportDialog />}
                  />
                  <Route
                    path={`${ObelisRoutes.ChargingStationDetailsReportsTab}/${ObelisRoutes.EditReport}`}
                    element={<ChargingStationReportDialog />}
                  />
                </Route>
              </Route>
              <Route
                path={ObelisRoutes.ChargingSessions}
                element={<ChargingSessionsPage />}
              ></Route>
              <Route
                path={`${ObelisRoutes.SystemReports}/*`}
                element={<SystemReportsPage />}
              >
                <Route
                  path={`${ObelisRoutes.CreateReport}/:selectedMonth`}
                  element={<SystemReportDialog />}
                />
                <Route
                  path={`${ObelisRoutes.EditReport}`}
                  element={<SystemReportDialog />}
                />
              </Route>
            </Route>
            <Route
              path={ESRoutes.NotFound}
              element={<NotFound navigateLink={ESRoutes.Root} />}
            />
            <Route
              path={ESRoutes.AccessDenied}
              element={
                <AccessDeniedPage
                  LoadingComponent={() => <ObelisLogoOverlay />}
                  userActive={true}
                  userHasRoleAssigned={true}
                />
              }
            />
            <Route
              path="*"
              element={<Navigate to={ESRoutes.NotFound} replace />}
            />
          </Routes>
        </ESOidcProvider>
      </ESAppProvider>
    </ESErrorBoundary>
  );
};

const AppShell = () => {
  const loginState = useAppSelector((state) => state.user.loginState);

  useLoadGoogleMapsApiScript(environment.googleApiKey);

  if (loginState === 'loading' || loginState === 'unauthenticated') {
    return <ObelisLogoOverlay />;
  }

  return (
    <>
      <Main>
        <SidebarWrapper />
      </Main>
    </>
  );
};

const SidebarWrapper = () => {
  return (
    <Sidebar
      logo={<DarkLogo />}
      items={
        <>
          <ChargingHubsDrawerItem />
          <ChargingStationsDrawerItem />
          <ChargingSessionsDrawerItem />
          <SystemReportsDrawerItem />
        </>
      }
      bottom={<ObelisSidebarBottom />}
    />
  );
};

const ObelisSidebarBottom = () => {
  return (
    <>
      <Divider sx={{ width: '100%', my: 4 }} />
      <ObelisProfileSection />
    </>
  );
};
