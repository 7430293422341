import {
  LATITUDE_OCPI_REGEX,
  LATITUDE_REGEX,
  LONGITUDE_OCPI_REGEX,
  LONGITUDE_REGEX,
} from '@energy-stacks/shared';
import * as yup from 'yup';
import { getShouldCityHaveUmlaut } from '../shared/getShouldCityHaveUmlaut';

const generalDetailsFormValidationSchema = yup.object().shape({
  id: yup.string().trim(),
  name: yup
    .string()
    .required('nameRequired')
    .trim()
    .max(255, 'chargingHubNameTooLong'),
  address: yup
    .string()
    .required('addressRequired')
    .trim()
    .max(45, 'chargingHubAddressTooLong'),
  postalCode: yup
    .string()
    .required('postalCodeRequired')
    .trim()
    .max(10, 'chargingHubPostalCodeTooLong'),
  city: yup
    .string()
    .required('cityRequired')
    .trim()
    .max(45, 'chargingHubCityTooLong')
    .test('checkForUmlauts', 'cityMustHaveUmlauts', (value) => {
      if (!value) return true;
      return getShouldCityHaveUmlaut(value);
    }),
  state: yup.object().shape({
    label: yup.string().required(),
    value: yup.string().required('chargingHubStateRequired'),
  }),
  coordinates: yup.object().shape({
    longitude: yup
      .string()
      .matches(LONGITUDE_REGEX, {
        message: 'longitudeValidation',
        excludeEmptyString: true,
      })
      .matches(LONGITUDE_OCPI_REGEX, {
        message: 'coordinateDecimals',
        excludeEmptyString: true,
      })
      .required('longitudeRequired')
      .when('latitude', {
        is: (value: string) => !!value,
        then: yup.string().required('coordinatesDependencyErrorMessage'),
        otherwise: yup.string().nullable(),
      }),
    latitude: yup.lazy(() =>
      yup
        .string()
        .matches(LATITUDE_REGEX, {
          message: 'latitudeValidation',
          excludeEmptyString: true,
        })
        .required('latitudeRequired')
        .matches(LATITUDE_OCPI_REGEX, {
          message: 'coordinateDecimals',
          excludeEmptyString: true,
        })
        .when('longitude', {
          is: (value: string) => !!value,
          then: yup.string().required('coordinatesDependencyErrorMessage'),
          otherwise: yup.string().nullable(),
        })
    ),
  }),
});

export const auxiliaryFacilitiesFormValidationSchema = yup.object({
  locations: yup.array().of(yup.string()),
  vehicleSupplies: yup.array().of(yup.string()),
  sustainability: yup.array().of(yup.string()),
});

export const editChargingHubValidationSchema = {
  generalDetails: generalDetailsFormValidationSchema,
  auxiliaryFacilities: auxiliaryFacilitiesFormValidationSchema,
};
