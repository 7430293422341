import { createApi } from '@reduxjs/toolkit/query/react';
import { environment } from '@energy-stacks/feature-config';
import {
  createBaseQuery,
  formatMonthYearToPayload,
} from '@energy-stacks/shared';
import { ReportRatingModel } from './rating-reports/reportRatingModel';
import { ProcessedRatingReportModel } from './rating-reports/processed-reports/processedRatingReportModel';
import { ProcessedRatingReportDto } from './rating-reports/processed-reports/processedRatingReportDto';
import { processedRatingReportsNormalizer } from './rating-reports/processed-reports/normalizers/processedReportsNormalizer';
import { convertReportRatingModelToDto } from './rating-reports/normalizers/reportRatingNormalizer';
import { ProcessedFacilityDamagesReportModel } from './facility-damages-reports/processed-reports/processedFacilityDamagesReportModel';
import { processedFacilityDamagesReportsNormalizer } from './facility-damages-reports/processed-reports/normalizers/processedFacilityDamagesReportsNormalizer';
import { ProcessedFacilityDamagesReportDto } from './facility-damages-reports/processed-reports/processedFacilityDamagesReportDto';
import {
  mapFacilityDamagesReportCreateDataToPayload,
  mapFacilityDamagesReportEditDataToPayload,
  ProcessedFacilityDamagesReportFormData,
} from './facility-damages-reports/mapFacilityDamagesReportToPayload';
import { ProcessedAdHocTariffReportModel } from './ad-hoc-tariff-reports/processed-reports/processedAdHocTariffReportModel';
import {
  adHocTariffProcessedReportCreateDataToPayload,
  adHocTariffProcessedReportEditDataToPayload,
} from './ad-hoc-tariff-reports/adHocTariffReportToPayload';
import { ProcessedAdHocTariffReportDto } from './ad-hoc-tariff-reports/processed-reports/processedAdHocTariffReportDto';
import { adHocTariffProcessedReportNormalizer } from './ad-hoc-tariff-reports/processed-reports/normalizers/adHocTariffProcessedReportNormalizer';
import { AdditionalCostsReportModel } from './ad-hoc-tariff-reports/additionalCostsReportModel';

interface CreateProcessedRatingReportBody {
  chargingHubUid: string;
  monthYear: Date;
  rating: ReportRatingModel;
}

interface EditProcessedRatingReportBody {
  monthYear: Date;
  rating: ReportRatingModel;
}

export type ProcessedAdHocTariffReportBody = {
  chargingHubUid: string;
  monthYear: Date;
  cost: string;
  additionalCosts?: AdditionalCostsReportModel[];
  validFrom: Date;
  validTo: Date;
};

export const chargingHubProcessedReportsApi = createApi({
  reducerPath: 'processedReportsApi',
  baseQuery: createBaseQuery(
    `${environment.ocppServiceUrl}/processed-reports/charging-hub`
  ),
  keepUnusedDataFor: 0,
  tagTypes: ['ProcessedReports', 'ProcessedReport'],
  endpoints: (builder) => ({
    createProcessedRatingReport: builder.mutation<
      void,
      CreateProcessedRatingReportBody
    >({
      query: (data) => ({
        url: `ratings`,
        method: 'POST',
        body: {
          ...data,
          monthYear: formatMonthYearToPayload(data.monthYear),
          rating: convertReportRatingModelToDto(data.rating),
        },
      }),
      invalidatesTags: ['ProcessedReports'],
    }),
    getProcessedRatingReportByReportUid: builder.query<
      ProcessedRatingReportModel,
      { uid: string }
    >({
      query: ({ uid }) => ({
        url: `ratings/${uid}`,
        method: 'GET',
      }),
      providesTags: (_result, _error, args) => [
        {
          type: 'ProcessedReport',
          id: args.uid,
        },
      ],
      transformResponse: (response: ProcessedRatingReportDto) => {
        return processedRatingReportsNormalizer(response);
      },
    }),
    editProcessedRatingReport: builder.mutation<
      void,
      { body: EditProcessedRatingReportBody; uid: string }
    >({
      query: ({ body, uid }) => ({
        url: `ratings/${uid}`,
        method: 'PUT',
        body: {
          monthYear: formatMonthYearToPayload(body.monthYear),
          rating: convertReportRatingModelToDto(body.rating),
        },
      }),
      invalidatesTags: (_result, error, args) =>
        !error
          ? [
              'ProcessedReports',
              {
                type: 'ProcessedReport',
                id: args.uid,
              },
            ]
          : [],
    }),
    createProcessedFacilityDamagesReport: builder.mutation<
      void,
      ProcessedFacilityDamagesReportFormData
    >({
      query: (data) => ({
        url: `facility-damages`,
        method: 'POST',
        body: mapFacilityDamagesReportCreateDataToPayload(data),
      }),
      invalidatesTags: ['ProcessedReports'],
    }),
    getProcessedFacilityDamagesReportByReportUid: builder.query<
      ProcessedFacilityDamagesReportModel,
      { uid: string }
    >({
      query: ({ uid }) => ({
        url: `facility-damages/${uid}`,
        method: 'GET',
      }),
      providesTags: (_result, _error, args) => [
        {
          type: 'ProcessedReport',
          id: args.uid,
        },
      ],
      transformResponse: (response: ProcessedFacilityDamagesReportDto) => {
        return processedFacilityDamagesReportsNormalizer(response);
      },
    }),
    editProcessedFacilityDamagesReport: builder.mutation<
      void,
      { body: ProcessedFacilityDamagesReportFormData; uid: string }
    >({
      query: ({ body, uid }) => ({
        url: `facility-damages/${uid}`,
        method: 'PUT',
        body: mapFacilityDamagesReportEditDataToPayload(body),
      }),
      invalidatesTags: (_result, error, args) =>
        !error
          ? [
              'ProcessedReports',
              {
                type: 'ProcessedReport',
                id: args.uid,
              },
            ]
          : [],
    }),
    createProcessedAdHocTariffReport: builder.mutation<
      void,
      ProcessedAdHocTariffReportBody
    >({
      query: (data) => ({
        url: `ad-hoc-tariff`,
        method: 'POST',
        body: adHocTariffProcessedReportCreateDataToPayload(data),
      }),
      invalidatesTags: ['ProcessedReports'],
    }),
    getProcessedAdHocTariffReportByReportUid: builder.query<
      ProcessedAdHocTariffReportModel,
      { uid: string }
    >({
      query: ({ uid }) => ({
        url: `ad-hoc-tariff/${uid}`,
        method: 'GET',
      }),
      providesTags: (_result, _error, args) => [
        {
          type: 'ProcessedReport',
          id: args.uid,
        },
      ],
      transformResponse: (response: ProcessedAdHocTariffReportDto) => {
        return adHocTariffProcessedReportNormalizer(response);
      },
    }),
    editProcessedAdHocTariffReport: builder.mutation<
      void,
      {
        body: Omit<ProcessedAdHocTariffReportBody, 'chargingHubUid'>;
        uid: string;
      }
    >({
      query: ({ body, uid }) => ({
        url: `ad-hoc-tariff/${uid}`,
        method: 'PUT',
        body: adHocTariffProcessedReportEditDataToPayload(body),
      }),
      invalidatesTags: (_result, error, args) =>
        !error
          ? [
              'ProcessedReports',
              {
                type: 'ProcessedReport',
                id: args.uid,
              },
            ]
          : [],
    }),
  }),
});

export const {
  useCreateProcessedRatingReportMutation,
  useGetProcessedRatingReportByReportUidQuery,
  useEditProcessedRatingReportMutation,
  useCreateProcessedFacilityDamagesReportMutation,
  useGetProcessedFacilityDamagesReportByReportUidQuery,
  useEditProcessedFacilityDamagesReportMutation,
  useCreateProcessedAdHocTariffReportMutation,
  useGetProcessedAdHocTariffReportByReportUidQuery,
  useEditProcessedAdHocTariffReportMutation,
} = chargingHubProcessedReportsApi;
