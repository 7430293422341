import {
  useCreateProcessedRenewalReportMutation,
  chargingStationsReportsApiErrors,
  chargingStationReportsApi,
  useEditProcessedRenewalReportMutation,
  RenewalReportFormData,
  ChargePointComponentDto,
  ChargePointComponentModel,
  ReasonModel,
  ReasonDto,
} from '@energy-stacks/obelis/feature-charging-station-reports-data';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
  useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import { ChargingStationReportContainer } from '../ChargingStationReportContainer';
import {
  ESDateTimePicker,
  ESTextField,
  ESVirtualizedAutocomplete,
  useESSnackbar,
} from '@energy-stacks/core/ui';
import { Box, Button, Grid, Typography } from '@mui/material';
import {
  ErrorApiResponse,
  formatDateTime,
  RefetchOnError,
  toPayloadDateObelis,
} from '@energy-stacks/shared';
import { IconPlus } from '@tabler/icons-react';
import { useAppDispatch } from '@energy-stacks/store';
import { editChargingStationRenewalReportSchema } from './editChargingStationRenewalReportSchema';
import { createChargingStationRenewalReportSchema } from './createChargingStationRenewalReportSchema';
import { ChargingStationReportContainerHeader } from '../ChargingStationReportContainerHeader';
import { RenewedComponent } from './RenewedComponent';
import { defaultRenewedComponents } from './defaultRenewedComponent';
import { useRenewalRawReport } from './useRenewalRawReport';
import { useRenewalProcessedReport } from './useRenewalProcessedReport';
import { isValid } from 'date-fns';
import { useChargingStationDetails } from '../useChargingStationDetails';

const chargePointComponentModelToDtoMap: Record<
  ChargePointComponentModel,
  ChargePointComponentDto
> = {
  cableCoolingUnit: 'CABLE_COOLING_UNIT',
  networkingElectronics: 'NETWORKING_ELECTRONICS',
  powerModule: 'POWER_MODULE',
  paymentDevice: 'PAYMENT_DEVICE',
  vehicleCommunicationUnit: 'VEHICLE_COMMUNICATION_UNIT',
  backendCommunicationUnit: 'BACKEND_COMMUNICATION_UNIT',
  display: 'DISPLAY',
  dcMeter: 'DC_METER',
  airFilter: 'AIR_FILTER',
  others: 'OTHERS',
} as const;

const reasonModelToDtoMap: Record<ReasonModel, ReasonDto> = {
  damage: 'DAMAGE',
  hardwareFault: 'HARDWARE_FAULT',
  softwareFault: 'SOFTWARE_FAULT',
  retrofitting: 'RETROFITTING',
  others: 'OTHERS',
} as const;

export const ChargingStationRenewalReportForm = ({
  setIsRatingFormDirty,
  setIsDisabled,
  activeStep,
  setActiveStep,
  isEditMode,
}: {
  setIsRatingFormDirty: (isRatingFormDirty: boolean) => void;
  setIsDisabled: (isDisabled: boolean) => void;
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
  isEditMode: boolean;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation('chargingStationReports');
  const { showSnackbar } = useESSnackbar();
  const [createProcessedRenewalReport, { isLoading: isCreating }] =
    useCreateProcessedRenewalReportMutation();
  const [editProcessedRenewalReport, { isLoading: isEditing }] =
    useEditProcessedRenewalReportMutation();
  const dispatch = useAppDispatch();
  const { uid, id } = useParams<{ uid: string; id: string }>();
  const {
    rawReport,
    isLoading: isRawReportLoading,
    error: rawDataError,
  } = useRenewalRawReport(isEditMode);
  const {
    processedReport,
    isLoading: isProcessedReportLoading,
    error: processedDataError,
  } = useRenewalProcessedReport(isEditMode);

  const reportData = isEditMode ? processedReport : rawReport;
  const tableValueHeader = isEditMode ? 'currentValue' : 'originalValue';

  const {
    chargingStationDetails,
    isError,
    isLoading: isDetailsLoading,
    refetch,
  } = useChargingStationDetails(isEditMode);

  const chargingPointEvsesOptions = chargingStationDetails?.evses?.map(
    (evse) => {
      return {
        label: evse.evseId,
        value: evse.evseId,
      };
    }
  );

  const defaultValues = useMemo(() => {
    return {
      evseId: reportData?.evseId || '',
      renewalUid: reportData?.renewalUid || '',
      renewedComponents:
        reportData?.renewedComponents && reportData.renewedComponents.length
          ? reportData.renewedComponents
          : [defaultRenewedComponents],
      start: reportData?.start ? new Date(reportData.start) : null,
      end: reportData?.end ? new Date(reportData.end) : null,
    };
  }, [reportData]);

  const validationSchema = isEditMode
    ? editChargingStationRenewalReportSchema
    : createChargingStationRenewalReportSchema;

  const formMethods = useForm<RenewalReportFormData>({
    defaultValues,
    mode: 'onTouched',
    resolver: yupResolver(validationSchema),
  });

  const {
    handleSubmit,
    formState: { isValid: isFormValid, isDirty: isFormDirty, errors },
    reset: resetForm,
    control,
    trigger,
  } = formMethods;

  const startDate = useWatch({
    control,
    name: 'start',
  });
  const endDate = useWatch({
    control,
    name: 'end',
  });

  const {
    fields: renewedComponents,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'renewedComponents',
  });

  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(false);

  const addRenewalButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (shouldScrollToBottom && addRenewalButtonRef?.current) {
      addRenewalButtonRef.current.scrollIntoView({ behavior: 'smooth' });
      setShouldScrollToBottom(false);
    }
  }, [shouldScrollToBottom]);

  const handleClose = useCallback(() => {
    resetForm();
    navigate(-1);
  }, [navigate, resetForm]);

  const isDisabled =
    isCreating ||
    isDetailsLoading ||
    isEditing ||
    isRawReportLoading ||
    isProcessedReportLoading;

  useEffect(() => {
    setIsRatingFormDirty(isFormDirty);
    setIsDisabled(isDisabled);
  }, [isFormDirty, setIsRatingFormDirty, setIsDisabled, isDisabled]);

  useEffect(() => {
    if (rawDataError) {
      if ('status' in rawDataError && rawDataError.status === 404) {
        return;
      } else {
        showSnackbar(
          'error',
          'rawReportFetchErrorMessage',
          'chargingStationReports'
        );
      }
    }
  }, [rawDataError, showSnackbar]);

  useEffect(() => {
    if (processedDataError) {
      if ('data' in processedDataError) {
        const response = processedDataError.data as ErrorApiResponse;
        processedDataError.data
          ? showSnackbar(
              'error',
              chargingStationsReportsApiErrors[response.errorCode],
              'chargingStationReports'
            )
          : showSnackbar(
              'error',
              'processedReportFetchErrorMessage',
              'chargingStationReports'
            );
      } else {
        showSnackbar(
          'error',
          'processedReportFetchErrorMessage',
          'chargingStationReports'
        );
      }
      handleClose();
    }
  }, [processedDataError, showSnackbar, handleClose]);

  const onSubmit: SubmitHandler<RenewalReportFormData> = (data) => {
    const evse = chargingStationDetails?.evses.find((evse) => {
      return evse.evseId === data.evseId;
    });

    const chargingPointUidPayload = isEditMode
      ? reportData?.chargingPointUid
      : evse?.chargingPointId;

    const chargingHubId =
      reportData?.chargingHubUid || chargingStationDetails?.chargingHub.id;

    if (
      !chargingPointUidPayload ||
      !id ||
      !chargingHubId ||
      !data.start ||
      !data.end
    ) {
      return;
    }

    const createBody = {
      uid: reportData?.uid ?? null,
      chargingHubUid: chargingHubId,
      identityKey: id,
      chargingPointUid: chargingPointUidPayload,
      renewalUid: data.renewalUid,
      renewedComponents: data?.renewedComponents.map((renewedComponent) => {
        return {
          chargePointComponent:
            chargePointComponentModelToDtoMap[
              renewedComponent.chargePointComponent
            ],
          reason: reasonModelToDtoMap[renewedComponent.reason],
        };
      }),
      start: toPayloadDateObelis(data.start),
      end: toPayloadDateObelis(data.end),
    };
    const editBody = {
      renewedComponents: data?.renewedComponents.map((renewedComponent) => {
        return {
          chargePointComponent:
            chargePointComponentModelToDtoMap[
              renewedComponent.chargePointComponent
            ],
          reason: reasonModelToDtoMap[renewedComponent.reason],
        };
      }),
      start: data.start ? toPayloadDateObelis(data?.start) : null,
      end: data?.end ? toPayloadDateObelis(data?.end) : null,
    };
    const successMessage = isEditMode
      ? 'editChargingStationRenewalSuccess'
      : 'addChargingStationRenewalSuccess';

    const reportHandler = isEditMode
      ? editProcessedRenewalReport({ body: editBody, uid: uid ?? '' })
      : createProcessedRenewalReport(createBody);

    reportHandler
      .unwrap()
      .then(() => {
        showSnackbar('success', successMessage, 'chargingStationReports');
        dispatch(
          chargingStationReportsApi.util.invalidateTags([
            'ChargingStationReports',
          ])
        );
        handleClose();
      })
      .catch((error) => {
        showSnackbar(
          'error',
          chargingStationsReportsApiErrors[error.data?.errorCode],
          'chargingStationReports'
        );
      });
  };

  useEffect(() => {
    resetForm(defaultValues);
  }, [defaultValues, resetForm]);

  if (isError) {
    return (
      <Box sx={{ mt: 50 }}>
        <RefetchOnError onRefetch={refetch} />
      </Box>
    );
  }

  return (
    <FormProvider {...formMethods}>
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <ChargingStationReportContainer
          title="renewal"
          isFormValid={isFormValid}
          isFormDirty={isFormDirty}
          isLoading={isCreating || isEditing}
          activeStep={activeStep ?? 1}
          setActiveStep={setActiveStep}
          isEditMode={isEditMode}
        >
          {!isEditMode ? (
            <Grid
              container
              sx={{
                padding: 4,
                paddingTop: 4,
                paddingBottom: 4,
                alignItems: 'center',
              }}
            >
              <Grid item xs={4} mr={6}>
                <Controller
                  control={control}
                  name="evseId"
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value } }) => {
                    return (
                      <ESVirtualizedAutocomplete
                        label={t('evseID')}
                        disabled={isDisabled}
                        required
                        value={{
                          label: value ?? '',
                          value: value ?? '',
                        }}
                        options={chargingPointEvsesOptions || []}
                        onBlur={onBlur}
                        blurOnSelect
                        onChange={(_, value) => {
                          if (Array.isArray(value)) {
                            return;
                          }
                          if (value) {
                            onChange(value.value);
                          }
                        }}
                        noOptionsText={t('noEvses')}
                        error={Boolean(errors['evseId'])}
                        helperText={
                          Boolean(errors['evseId']) &&
                          t(`${errors['evseId']?.message}`)
                        }
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  control={control}
                  name="renewalUid"
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value } }) => {
                    return (
                      <ESTextField
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={Boolean(errors['renewalUid'])}
                        helperText={
                          Boolean(errors['renewalUid']) &&
                          t(`${errors['renewalUid']?.message}`)
                        }
                        disabled={isDisabled}
                        required
                        label={t('evseRenewalId')}
                        placeholder={reportData?.renewalUid ?? '-'}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          ) : null}
          <ChargingStationReportContainerHeader
            tableValueHeader={tableValueHeader}
          />
          <Grid
            container
            spacing={8}
            sx={{
              padding: 4,
              paddingTop: 4,
              paddingBottom: 4,
              alignItems: 'center',
            }}
          >
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight={500} color={'grey.600'}>
                {t('start') + ' *'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              {reportData?.start ? formatDateTime(reportData.start) : '-'}
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="start"
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <ESDateTimePicker
                      value={value}
                      onChange={(value) => {
                        onChange(value);
                        if (isValid(endDate)) {
                          trigger('end');
                        }
                      }}
                      onBlur={onBlur}
                      onClose={() => trigger('start')}
                      error={Boolean(errors['start'])}
                      helperText={
                        Boolean(errors['start']) &&
                        t(`${errors['start']?.message}`)
                      }
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight={500} color={'grey.600'}>
                {t('end') + ' *'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              {reportData?.end ? formatDateTime(reportData.end) : '-'}
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="end"
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <ESDateTimePicker
                      value={value}
                      minDateTime={startDate || undefined}
                      onChange={onChange}
                      onBlur={onBlur}
                      onClose={() => trigger('end')}
                      error={Boolean(errors['end'])}
                      helperText={
                        Boolean(errors['end']) && t(`${errors['end']?.message}`)
                      }
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
          <Box p={3}>
            {renewedComponents.map((renewedComponent, index) => {
              return (
                <RenewedComponent
                  key={renewedComponent.id}
                  componentIndex={index}
                  isDisabled={isDisabled}
                  onRemove={remove}
                  renewedComponent={renewedComponent}
                  isRemovable={Boolean(renewedComponents.length > 1)}
                />
              );
            })}
            <Button
              ref={addRenewalButtonRef}
              data-testid="addRenewedComponentButton"
              variant="text"
              startIcon={<IconPlus />}
              onClick={() => {
                append(defaultRenewedComponents);
                setShouldScrollToBottom(true);
              }}
            >
              {t('addRenewal')}
            </Button>
          </Box>
        </ChargingStationReportContainer>
      </form>
    </FormProvider>
  );
};
