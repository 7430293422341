import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';

export const ChargingStationReportContainerHeader = ({
  tableValueHeader,
}: {
  tableValueHeader: string;
}) => {
  const { t } = useTranslation('chargingStationReports');

  return (
    <>
      <Grid
        container
        spacing={8}
        sx={{
          padding: '16px',
          alignItems: 'center',
          borderBottom: '1px solid',
          borderColor: 'grey.100',
        }}
      >
        <Grid item xs={4}>
          <Typography variant="subtitle1" fontWeight={700}>
            {t('key')}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1" fontWeight={700}>
            {t(tableValueHeader)}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1" fontWeight={700}>
            {t('newValue')}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
