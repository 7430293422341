import {
  TariffTypeModel,
  tariffTypesModel,
} from '@energy-stacks/obelis/feature-charging-sessions-data';
import { TableFilterButton } from '@energy-stacks/shared';
import { Box, MenuItem, Radio, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ChargingSessionsTableTariffTypeFilterProps {
  tariffTypeFilter: TariffTypeModel | undefined;
  onSetTariffTypeFilter: (
    tariffTypeFilter: TariffTypeModel | undefined
  ) => void;
  testId?: string;
}

export const ChargingSessionsTableTariffTypeFilter: React.FC<
  ChargingSessionsTableTariffTypeFilterProps
> = ({ testId, tariffTypeFilter, onSetTariffTypeFilter }) => {
  const { t } = useTranslation('chargingSessions');

  const tariffTypes = tariffTypesModel.map((tariffType) => {
    return {
      label: t(tariffType),
      value: tariffType,
    };
  });

  return (
    <TableFilterButton
      testId={testId}
      title={t('tariffType')}
      renderPopover={(closePopover) => {
        return (
          <Box
            sx={{
              maxHeight: 450,
              minWidth: 176,
            }}
          >
            <Box>
              {tariffTypes.map((tariffType) => {
                return (
                  <MenuItem
                    data-testid={`${testId}MenuOption${tariffType.label}`}
                    dense
                    selected={tariffType.value === tariffTypeFilter}
                    key={tariffType.value}
                    onClick={() => {
                      onSetTariffTypeFilter(
                        tariffType.value as TariffTypeModel
                      );
                      closePopover();
                    }}
                  >
                    <Radio
                      checked={tariffType.value === tariffTypeFilter}
                      value={tariffType}
                    />
                    <Typography>{tariffType.label}</Typography>
                  </MenuItem>
                );
              })}
            </Box>
          </Box>
        );
      }}
      isActive={Boolean(tariffTypeFilter)}
    />
  );
};
