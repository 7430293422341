export const getShouldCityHaveUmlaut = (value: string) => {
  const hasDiphthongs = UMLAUT_DIPHTHONGS.some((d) => value.includes(d));
  if (!hasDiphthongs) return true;
  const isExcluded = UMLAUT_DIPHTHONGS_GERMAN_CITIES.some(
    (c) => c === value.trim().toLocaleLowerCase()
  );
  if (isExcluded) return true;
  return false;
};

const UMLAUT_DIPHTHONGS = ['ae', 'oe', 'ue'];

const UMLAUT_DIPHTHONGS_GERMAN_CITIES = [
  'baesweiler',
  'kevelaer',
  'straelen',
  'baerl',
  'bad oeynhausen',
  'bad oldesloe',
  'buchloe',
  'coesfeld',
  'itzehoe',
  'moers',
  'oebisfelde-weferlingen',
  'oederan',
  'oelde',
  'oelsnitz/erzgeb.',
  'oelsnitz/vogtl.',
  'oer-erkenschwick',
  'oerlinghausen',
  'oestrich-winkel',
  'oettingen',
  'soest',
  'voerde',
  'aue-bad schlema',
  'auerbach in der oberpfalz',
  'auerbach/vogtl',
  'bad neuenahr-ahrweiler',
  'bernkastel-kues',
  'brüel',
  'donaueschingen',
  'frauenstein',
  'hohen neuendorf',
  'lauenburg/elbe',
  'menden (sauerland)',
  'neuenbürg',
  'neuenburg am rhein',
  'neuenhaus',
  'neuenrade',
  'neuenstadt am kocher',
  'neuenstein',
  'neuerburg',
  'plaue',
  'plauen',
  'rauenberg',
  'schauenstein',
  'treuen',
  'treuenbrietzen',
  'uebigau-wahrenbrück',
  'ueckermünde',
  'uelzen',
  'uetersen',
  'ruegen',
  'buer',
  'suelzbach',
  'duelmen',
];
