import {
  NoTableData,
  TableSearchField,
  ClearFiltersButton,
  MoreOptionsMenu,
  TableColumnSelect,
  ESTooltip,
  formatDate,
  TableColumnFilter,
} from '@energy-stacks/shared';
import { useTranslation } from 'react-i18next';
import {
  ESTable,
  ESTableBody,
  ESTablePagination,
  ESTableWrapper,
  ESTableHead,
  ESTableBasicConfig,
  useFitRows,
} from '@energy-stacks/core/ui';
import {
  ChargingStation,
  ChargingStationsModel,
  statuses,
} from '@energy-stacks/obelis/feature-charging-stations-data';
import {
  createColumnHelper,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  Updater,
  useReactTable,
} from '@tanstack/react-table';
import { Box, MenuItem, Stack } from '@mui/material';
import { ConnectorIcon } from '@energy-stacks/broker/shared';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { ChargingStationStatusChip } from './ChargingStationStatusChip';
import { DeleteChargingStationDialog } from './DeleteChargingStationDialog';

interface ChargingStationsTableProps
  extends ESTableBasicConfig<ChargingStation> {
  chargingStations: ChargingStationsModel;
  testId?: string;
  search: string;
  setSearch: (searchInput: string) => void;
  pagination: PaginationState;
  onPaginationChange: (pagination: Updater<PaginationState>) => void;
  sorting: SortingState;
  onSortingChange: (pagination: Updater<SortingState>) => void;
  onRowClick: (chargingStationId: string) => void;
  onEdit: (chargingStationId: string) => void;
  action?: ReactNode;
}

export const ChargingStationsTable: React.FC<ChargingStationsTableProps> = ({
  chargingStations,
  testId,
  search,
  setSearch,
  sorting,
  onPaginationChange,
  pagination,
  onSortingChange,
  onRowClick,
  onEdit,
  action,
}) => {
  const [t] = useTranslation('chargingStations');
  const [tSharedChargingStations] = useTranslation('sharedChargingStations');
  const [chargingStationToDelete, setChargingStationToDelete] = useState<
    ChargingStation | undefined
  >(undefined);

  const columnHelper = createColumnHelper<ChargingStation>();

  const columns = [
    columnHelper.accessor('id', {
      sortingFn: 'alphanumeric',
      header: () => t('idColumnHeader'),
      footer: (props) => props.column.id,
      cell: (info) => info.getValue() || '-',
    }),
    columnHelper.accessor('name', {
      sortingFn: 'alphanumeric',
      header: () => t('nameColumnHeader'),
      footer: (props) => props.column.id,
      cell: (info) => info.getValue() || '-',
    }),
    columnHelper.accessor('model', {
      sortingFn: 'alphanumeric',
      header: () => t('modelColumnHeader'),
      footer: (props) => props.column.id,
      cell: (info) => info.getValue() || '-',
    }),
    columnHelper.accessor('lastUpdated', {
      header: () => t('lastUpdatedColumnHeader'),
      footer: (props) => props.column.id,
      cell: (info) => {
        const value = info.getValue();
        return value ? formatDate(value) : '-';
      },
    }),
    columnHelper.accessor('status', {
      header: () => t('statusColumnHeader'),
      footer: (props) => props.column.id,
      cell: (info) => {
        const status = info.getValue();
        return status && <ChargingStationStatusChip status={status} />;
      },
      filterFn: 'arrIncludesSome',
    }),
    columnHelper.accessor('evses', {
      header: () => t('connectorsColumnHeader'),
      footer: (props) => props.column.id,
      cell: (info) => {
        const connectors = info.getValue().flatMap((evse) => evse.connectors);
        return (
          <Stack flexDirection="row" gap={2}>
            {connectors.map((connector) => {
              return (
                <ESTooltip
                  title={
                    <div style={{ textAlign: 'left' }}>
                      <span>{`${t('connectorTooltipLabels.id')}: ${
                        connector.id
                      }`}</span>
                      <br></br>
                      <span>{`${t('connectorTooltipLabels.status')}: ${t(
                        `statuses.${connector.status}`
                      )}`}</span>
                      <br></br>
                      <span>{`${t('connectorTooltipLabels.type')}: ${
                        connector.type
                          ? tSharedChargingStations(
                              `connectorTypes.${connector.type}`
                            )
                          : 'N/A'
                      }`}</span>
                    </div>
                  }
                  key={connector.id}
                  placement="bottom"
                >
                  <ConnectorIcon connectorType={connector.type} />
                </ESTooltip>
              );
            })}
          </Stack>
        );
      },
      enableSorting: false,
      enableGlobalFilter: false,
    }),
    columnHelper.display({
      id: 'actions',
      meta: { isActionable: true },
      header: () => t('actionsColumnHeader'),
      footer: (props) => props.column.id,
      cell: (info) => (
        <MoreOptionsMenu testId={info.row.original.id + testId}>
          <MenuItem onClick={() => onEdit(info.row.original.id)}>
            {t('editChargingStationMenuItem')}
          </MenuItem>
          <MenuItem
            onClick={() => setChargingStationToDelete(info.row.original)}
          >
            {t('deleteChargingStationMenuItem')}
          </MenuItem>
        </MoreOptionsMenu>
      ),
    }),
  ];

  const instance = useReactTable({
    data: chargingStations.content,
    columns,
    state: {
      pagination,
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange,
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange,
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    pageCount: chargingStations?.totalPages,
    enableColumnResizing: false,
  });

  const { rowsPerPageOptions } = useFitRows(
    instance,
    chargingStations?.totalElements ?? 0
  );

  const filteredRows = instance.getFilteredRowModel().rows;
  const rows = instance.getRowModel().rows;
  const hasRows = rows.length !== 0;

  const onSearchChange = useCallback(
    (value: string) => {
      const trimmedNewValue = value.trim();
      setSearch(trimmedNewValue);
    },
    [setSearch]
  );

  const statusFilterOptions = useMemo(() => {
    return statuses.map((status) => ({
      value: status,
      label: t(`statuses.${status}`),
    }));
  }, [t]);

  return (
    <>
      <Box display="flex" mb={6} pr={3} alignItems="center">
        <Box flex={1}>
          <Stack direction="row" gap={3} alignItems="center">
            <ESTooltip title={t('chargingStationsTableSearchPlaceholder')}>
              <TableSearchField
                testId={`${testId}SearchField`}
                value={search}
                onChange={onSearchChange}
                debounce={500}
                tableInstance={instance}
                placeholder={t('chargingStationsTableSearchPlaceholder')}
              />
            </ESTooltip>
            <TableColumnFilter
              testId={`${testId}StatusFilter`}
              column={instance.getColumn('status')}
              columnLabel={t('statusColumnFilterLabel')}
              options={statusFilterOptions}
            />
            <ClearFiltersButton tableInstance={instance} />
            <Box sx={{ marginLeft: 'auto', px: 2 }} display="flex">
              <TableColumnSelect instance={instance} />
              {action && action}
            </Box>
          </Stack>
        </Box>
      </Box>
      <ESTableWrapper>
        <ESTable>
          <ESTableHead testId={testId} instance={instance} />
          <ESTableBody
            testId={testId}
            rows={rows}
            onRowClick={(row) => onRowClick(row.original.id)}
          />
        </ESTable>

        {!hasRows ? <NoTableData message={t('noChargingStations')} /> : null}

        <ESTablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          instance={instance}
          count={chargingStations?.totalElements || filteredRows.length}
        />
      </ESTableWrapper>
      {chargingStationToDelete && (
        <DeleteChargingStationDialog
          chargingStationDetails={{
            chargingStationId: chargingStationToDelete.id,
            chargingStationName: chargingStationToDelete.name,
            hasEVSEs: Boolean(chargingStationToDelete.evses.length),
          }}
          onClose={() => setChargingStationToDelete(undefined)}
        />
      )}
    </>
  );
};
