import { ESDialogTitle, showSnackbar } from '@energy-stacks/core/ui';
import {
  ConfirmCloseDialog,
  useConfirmCloseDialog,
} from '@energy-stacks/shared';
import {
  Box,
  CircularProgress,
  Dialog,
  IconButton,
  useTheme,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { IconX } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { resetStepper, useAppSelector } from '@energy-stacks/store';
import { editChargingHubSteps } from './editChargingHubSteps';
import {
  EditChargingHubForm,
  EditChargingHubFormData,
} from './EditChargingHubForm';
import { useNavigate } from 'react-router-dom';
import { useChargingHubDetails } from '../details/useChargingHubDetails';

export const EditChargingHub = () => {
  const dispatch = useDispatch();
  const [isDialogOpen, setIsDialogOpen] = useState(true);
  const [t] = useTranslation('chargingHubs');
  const activeStep = useAppSelector((state) => state.stepper.stepperActiveStep);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const { palette } = useTheme();
  const navigate = useNavigate();
  const { chargingHubDetails, isError, isSuccess } = useChargingHubDetails();

  useEffect(() => {
    return () => {
      dispatch(resetStepper());
    };
  }, [dispatch]);

  const defaultValues: EditChargingHubFormData | undefined = useMemo(() => {
    return chargingHubDetails && isSuccess
      ? {
          id: chargingHubDetails.id,
          name: chargingHubDetails.name,
          address: chargingHubDetails.address,
          postalCode: chargingHubDetails.postalCode,
          city: chargingHubDetails.city,
          state: {
            label: chargingHubDetails.state,
            value: chargingHubDetails.state,
          },
          coordinates: {
            longitude: chargingHubDetails.coordinates.longitude,
            latitude: chargingHubDetails.coordinates.latitude,
          },
          auxiliaryFacilities: chargingHubDetails.auxiliaryFacilities,
        }
      : undefined;
  }, [chargingHubDetails, isSuccess]);

  useEffect(() => {
    if (isError) {
      showSnackbar('error', 'chargingHubDetailsFetchingError', 'chargingHubs');
      navigate(-1);
    }
  }, [isError, navigate]);

  const {
    isConfirmCloseDialogOpen,
    handleOpenConfirmDialog,
    handleStay,
    handleLeaveWithoutSaving,
  } = useConfirmCloseDialog(isFormDirty);

  return (
    <>
      <Dialog
        open={isDialogOpen}
        onClose={handleOpenConfirmDialog}
        maxWidth="lg"
        fullWidth
      >
        <ESDialogTitle
          data-testid="editChargingHubDialogTitle"
          sx={{
            borderBottom: `1px solid ${palette.grey[300]}`,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            pb: 4,
          }}
          justifyContent="space-between"
        >
          {t('editChargingHub')}
          <IconButton
            data-testid="editStationCloseModalButton"
            onClick={handleOpenConfirmDialog}
          >
            <IconX />
          </IconButton>
        </ESDialogTitle>
        {defaultValues && isSuccess ? (
          <EditChargingHubForm
            display={editChargingHubSteps[activeStep].name}
            onSetIsDialogOpen={setIsDialogOpen}
            activeStep={activeStep}
            onFormDirty={setIsFormDirty}
            defaultValues={defaultValues}
          />
        ) : (
          <Box
            height={565}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress size={50} />
          </Box>
        )}
      </Dialog>
      <ConfirmCloseDialog
        open={isConfirmCloseDialogOpen}
        onStay={handleStay}
        onLeaveWithoutSaving={handleLeaveWithoutSaving}
        testId="editChargingHub"
      />
    </>
  );
};
