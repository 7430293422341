import { ESVirtualizedAutocomplete } from '@energy-stacks/core/ui';
import {
  chargePointComponentModel,
  reasonModel,
  RenewedComponentsModel,
} from '@energy-stacks/obelis/feature-charging-station-reports-data';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import {
  Controller,
  UseFieldArrayRemove,
  useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IconX } from '@tabler/icons-react';

interface RenewedComponentsProps {
  isDisabled: boolean;
  componentIndex: number;
  onRemove: UseFieldArrayRemove;
  renewedComponent: RenewedComponentsModel | undefined;
}

export const RenewedComponents: React.FC<RenewedComponentsProps> = ({
  isDisabled,
  componentIndex,
  onRemove,
  renewedComponent,
}) => {
  const { t } = useTranslation('chargingStationReports');

  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Box
      sx={{
        p: 4,
        pt: 12,
        backgroundColor: 'grey.100',
        borderRadius: (theme) => theme.spacing(3),
        mb: 3,
        position: 'relative',
      }}
    >
      <Box
        sx={{
          textAlign: 'right',
          position: 'absolute',
          top: '5px',
          right: '7px',
        }}
        mb={2}
      >
        <IconButton onClick={() => onRemove(componentIndex)}>
          <IconX />
        </IconButton>
      </Box>
      <Grid
        container
        sx={{
          alignItems: 'center',
        }}
      >
        <Grid item xs={4}>
          <Typography variant="body1" fontWeight={500} color={'grey.600'}>
            {t('renewedComponent') + ' *'}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1">
            {renewedComponent?.chargePointComponent
              ? t(renewedComponent.chargePointComponent)
              : '-'}
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ mb: 4 }}>
          <Controller
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <ESVirtualizedAutocomplete
                  label=""
                  disabled={isDisabled}
                  value={{
                    label: value.label ?? '',
                    value: value.value ?? '',
                  }}
                  options={chargePointComponentModel.map((option) => ({
                    label: t(option),
                    value: option,
                  }))}
                  onBlur={onBlur}
                  blurOnSelect
                  onChange={(_, value) => {
                    if (Array.isArray(value)) {
                      return;
                    }
                    if (value) {
                      onChange(value);
                    }
                  }}
                  required
                  error={Boolean(
                    errors['renewedComponents']?.[componentIndex]
                      ?.chargePointComponent
                  )}
                  helperText={
                    Boolean(
                      errors['renewedComponents']?.[componentIndex]
                        ?.chargePointComponent
                    ) &&
                    t(
                      `${errors['renewedComponents']?.[componentIndex]?.chargePointComponent?.value?.message}`
                    )
                  }
                  placeholder={
                    renewedComponent?.chargePointComponent
                      ? t(renewedComponent?.chargePointComponent)
                      : '-'
                  }
                />
              );
            }}
            name={`renewedComponents.${componentIndex}.chargePointComponent`}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1" fontWeight={500} color={'grey.600'}>
            {t('reason') + ' *'}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1">
            {renewedComponent?.reason ? t(renewedComponent.reason) : '-'}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Controller
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <ESVirtualizedAutocomplete
                  label=""
                  required
                  disabled={isDisabled}
                  value={{
                    label: value.label ?? '',
                    value: value.value ?? '',
                  }}
                  options={reasonModel.map((option) => {
                    return {
                      label: t(option),
                      value: option,
                    };
                  })}
                  onBlur={onBlur}
                  blurOnSelect
                  onChange={(_, value) => {
                    if (Array.isArray(value)) {
                      return;
                    }
                    if (value) {
                      onChange(value);
                    }
                  }}
                  error={Boolean(
                    errors['renewedComponents']?.[componentIndex]?.reason
                  )}
                  helperText={
                    Boolean(
                      errors['renewedComponents']?.[componentIndex]?.reason
                    ) &&
                    t(
                      `${errors['renewedComponents']?.[componentIndex]?.reason.value?.message}`
                    )
                  }
                  placeholder={
                    renewedComponent?.reason ? t(renewedComponent?.reason) : '-'
                  }
                />
              );
            }}
            name={`renewedComponents.${componentIndex}.reason`}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
