import { isAfter, isBefore, isValid } from 'date-fns';
import * as yup from 'yup';

const renewedComponent = yup.object().shape({
  chargePointComponent: yup.object({
    label: yup.string(),
    value: yup.string().required('renewedComponentRequired'),
  }),
  reason: yup.object({
    label: yup.string(),
    value: yup.string().required('renewedComponentRequired'),
  }),
});

export const createChargingStationRenewalReportSchema = yup.object().shape({
  evseId: yup.object({
    label: yup.string(),
    value: yup.string().required('evseIdRequired'),
  }),
  renewalUid: yup
    .string()
    .trim()
    .required('renewalUidRequired')
    .max(36, 'renewalUidTooLong'),
  renewedComponents: yup
    .array()
    .of(renewedComponent)
    .min(1, 'atLeastOneRenewed'),
  start: yup
    .date()
    .required('startRequired')
    .when('end', {
      is: (value: Date | null) => isValid(value),
      then: yup
        .date()
        .typeError('invalidStartDate')
        .max(yup.ref('end'), 'startDateAfterEndDate')
        .nullable(),
      otherwise: yup.date().nullable(),
    })
    .test(
      'Validate Start',
      'startNeedsBeforeEnd',
      (startTime, { parent: { end: endTime } }) => {
        if (!startTime || !endTime) {
          return true;
        }
        return isAfter(endTime, startTime);
      }
    ),
  end: yup.lazy(() =>
    yup
      .date()
      .required('endRequired')
      .when('start', {
        is: (value: Date | null) => isValid(value),
        then: yup
          .date()
          .typeError('invalidEndDate')
          .min(yup.ref('start'), 'endDateBeforeStartDate'),
        otherwise: yup.date().nullable(),
      })
      .test(
        'Validate End',
        'endNeedsAfterStart',
        (endTime, { parent: { start: startTime } }) => {
          if (!startTime || !endTime) {
            return true;
          }

          return isBefore(startTime, endTime);
        }
      )
  ),
});
