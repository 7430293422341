import * as yup from 'yup';
import { isAfter, isBefore, isValid } from 'date-fns';

export const chargingHubFacilityDamagesReportValidationSchema = yup
  .object()
  .shape({
    facilityType: yup.string().nullable().required('facilityTypeIsRequired'),
    emergenceDate: yup
      .date()
      .required('emergenceDateFieldIsRequired')
      .nullable()
      .typeError('invalidEmergenceDate')
      .when('forecastDate', {
        is: (value: Date | null) => isValid(value),
        then: yup
          .date()
          .max(
            yup.ref('forecastDate'),
            'reportEmergenceDateIsLaterThanForecastDate'
          ),
      })
      .when('fixDate', {
        is: (value: Date | null) => isValid(value),
        then: yup
          .date()
          .max(yup.ref('fixDate'), 'reportEmergenceDateIsLaterThanFixDate'),
      })
      .test(
        'Validate EmergenceDate to ForecastDate',
        'reportEmergenceDateIsLaterThanForecastDate',
        (emergenceDate, { parent: { forecastDate } }) => {
          if (!emergenceDate || !forecastDate) {
            return true;
          }
          return isAfter(forecastDate, emergenceDate);
        }
      )
      .test(
        'Validate EmergenceDate to FixDate',
        'reportEmergenceDateIsLaterThanFixDate',
        (emergenceDate, { parent: { fixDate } }) => {
          if (!emergenceDate || !fixDate) {
            return true;
          }
          return isAfter(fixDate, emergenceDate);
        }
      ),
    forecastDate: yup.lazy(() =>
      yup
        .date()
        .notRequired()
        .nullable()
        .when('emergenceDate', {
          is: (value: Date | null) => isValid(value),
          then: yup
            .date()
            .notRequired()
            .nullable()
            .typeError('invalidForecastDate')
            .min(
              yup.ref('emergenceDate'),
              'reportForecastDateIsBeforeOrEqualToEmergenceDate'
            ),
          otherwise: yup.date().nullable(),
        })
        .test(
          'Validate ForecastDate',
          'reportForecastDateIsBeforeOrEqualToEmergenceDate',
          (forecastDate, { parent: { emergenceDate } }) => {
            if (!forecastDate || !emergenceDate) {
              return true;
            }
            return isBefore(emergenceDate, forecastDate);
          }
        )
    ),
    fixDate: yup.lazy(() =>
      yup
        .date()
        .notRequired()
        .nullable()
        .when('emergenceDate', {
          is: (value: Date | null) => isValid(value),
          then: yup
            .date()
            .notRequired()
            .nullable()
            .typeError('invalidFixDate')
            .min(
              yup.ref('emergenceDate'),
              'reportFixDateIsBeforeOrEqualToEmergenceDate'
            ),
          otherwise: yup.date().nullable(),
        })
        .test(
          'Validate FixDate',
          'reportFixDateIsBeforeOrEqualToEmergenceDate',
          (fixDate, { parent: { emergenceDate } }) => {
            if (!fixDate || !emergenceDate) {
              return true;
            }
            return isBefore(emergenceDate, fixDate);
          }
        )
    ),
  });
