import * as yup from 'yup';
import { isBefore, isValid } from 'date-fns';
import { numberValidityTest } from '@energy-stacks/shared';

const additionalCostsValidationSchema = yup.object().shape({
  cost: numberValidityTest.required('additionalCostIsRequired'),
  description: yup.string().required('additionalCostDescriptionIsRequired'),
});

export const chargingHubAdHocTariffReportValidationSchema = yup.object().shape({
  monthYear: yup.date().required('monthIsRequired'),
  cost: numberValidityTest.required('costFieldIsRequired'),
  validFrom: yup
    .date()
    .typeError('invalidValidFromDate')
    .required('validFromDateFieldIsRequired')
    .when('validTo', {
      is: (value: Date | null) => isValid(value),
      then: yup.date().typeError('invalidValidFromDate'),
    }),
  validTo: yup.lazy(() =>
    yup
      .date()
      .typeError('invalidValidToDate')
      .required('validToDateFieldIsRequired')
      .when('validFrom', {
        is: (value: Date | null) => isValid(value),
        then: yup
          .date()
          .min(yup.ref('validFrom'), 'validToDateIsBeforeOrEqualToValidFrom')
          .typeError('invalidValidToDate'),
      })
      .test(
        'Validate ValidToDate',
        'validToDateIsBeforeOrEqualToValidFrom',
        (validTo, { parent: { validFrom } }) => {
          if (!validFrom || !validTo) {
            return true;
          }
          return isBefore(validFrom, validTo);
        }
      )
  ),
  additionalCosts: yup
    .array()
    .of(additionalCostsValidationSchema)
    .nullable()
    .when('$additionalCostsExists', {
      is: true,
      otherwise: yup.array().notRequired(),
    }),
});
