import { isAfter, isBefore, isValid } from 'date-fns';
import * as yup from 'yup';

export const createChargingStationDefectReportSchema = yup.object().shape({
  evseId: yup.object({
    label: yup.string(),
    value: yup.string().required('evseIdRequired'),
  }),
  type: yup.object({
    label: yup.string(),
    value: yup.string().required('typeRequired'),
  }),
  status: yup.object({
    label: yup.string(),
    value: yup.string().required('statusRequired'),
  }),
  activities: yup.object({
    label: yup.string(),
    value: yup.string().required('activitiesRequired'),
  }),
  additionalInformation: yup.object({
    label: yup.string(),
    value: yup.string(),
  }),
  emergenceDate: yup
    .date()
    .when('forecastDate', {
      is: (value: Date | null) => isValid(value),
      then: yup
        .date()
        .typeError('invalidDate')
        .max(yup.ref('forecastDate'), 'needsBeforeForecastDate')
        .nullable(),
      otherwise: yup.date().nullable(),
    })
    .when('fixDate', {
      is: (value: Date | null) => isValid(value),
      then: yup
        .date()
        .typeError('invalidDate')
        .max(yup.ref('fixDate'), 'needsBeforeFixDate')
        .nullable(),
      otherwise: yup.date().nullable(),
    })
    .test(
      'Validate Emergence',
      'needsBeforeForecastDate',
      (startTime, { parent: { end: forecastDate } }) => {
        if (!startTime || !forecastDate) {
          return true;
        }
        return isAfter(forecastDate, startTime);
      }
    )
    .required('emergenceDateRequired'),
  forecastDate: yup.lazy(() =>
    yup
      .date()
      .when('emergenceDate', {
        is: (value: Date | null) => isValid(value),
        then: yup
          .date()
          .typeError('invalidDate')
          .min(yup.ref('emergenceDate'), 'needsAfterEmergenceDate')
          .nullable(),
        otherwise: yup.date().nullable(),
      })
      .test(
        'Validate Forecast',
        'needsAfterEmergenceDate',
        (endTime, { parent: { start: emergenceDate } }) => {
          if (!emergenceDate || !endTime) {
            return true;
          }

          return isBefore(emergenceDate, endTime);
        }
      )
      .nullable()
  ),
  fixDate: yup.date().nullable().typeError('invalidDate'),
});
