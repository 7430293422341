import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from 'react-hook-form';
import { isValid, subMonths } from 'date-fns';
import { Grid, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ESDateTimePicker,
  ESTextField,
  ESVirtualizedAutocomplete,
  useESSnackbar,
} from '@energy-stacks/core/ui';
import { useAppDispatch } from '@energy-stacks/store';
import {
  NonDiscriminatoryAccessReportContractualBasisModel,
  nonDiscriminatoryAccessReportContractualBasisModel,
  systemProcessedReportsApi,
  systemProcessedReportsApiErrors,
  systemRawReportsApiErrors,
  useCreateSystemProcessedNonDiscriminatoryAccessReportMutation,
  useEditSystemProcessedNonDiscriminatoryAccessReportMutation,
} from '@energy-stacks/obelis/feature-system-reports-data';
import { ErrorApiResponse, formatDateTime } from '@energy-stacks/shared';
import { systemNonDiscriminatoryAccessReportValidationSchema } from './systemNonDiscriminatoryAccessReportValidationSchema';
import { AdditionalCosts } from './AdditionalCosts';
import { useSystemNonDiscriminatoryAccessRawReport } from './useSystemNonDiscriminatoryAccessRawReport';
import { useSystemNonDiscriminatoryAccessProcessedReport } from './useSystemNonDiscriminatoryAccessProcessedReport';
import { SystemReportContainer } from '../SystemReportContainer';
import {
  getReportDefaultDate,
  MonthYearPicker,
} from '@energy-stacks/obelis/shared';
import { AdditionalCostsReportModel } from '@energy-stacks/obelis/feature-charging-hub-reports-data';

export type ProcessedNonDiscriminatoryAccessReportFormData = {
  monthYear: Date;
  providerGroup: string;
  cost: string;
  additionalCosts?: AdditionalCostsReportModel[];
  validFrom: Date | null;
  validTo: Date | null;
  contractualBasis: NonDiscriminatoryAccessReportContractualBasisModel;
  reasoningDifferentCosts?: string;
};

const lastMonth = subMonths(new Date(), 1);

export const SystemNonDiscriminatoryAccessReportForm = ({
  setIsNonDiscriminatoryAccessFormDirty,
  setIsDisabled,
  activeStep,
  setActiveStep,
  isEditMode,
}: {
  setIsNonDiscriminatoryAccessFormDirty: (
    isNonDiscriminatoryAccessFormDirty: boolean
  ) => void;
  setIsDisabled: (isDisabled: boolean) => void;
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
  isEditMode: boolean;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation('systemReports');
  const [
    createProcessedNonDiscriminatoryAccessReport,
    { isLoading: isCreating },
  ] = useCreateSystemProcessedNonDiscriminatoryAccessReportMutation();
  const [editProcessedNonDiscriminatoryAccessReport, { isLoading: isEditing }] =
    useEditSystemProcessedNonDiscriminatoryAccessReportMutation();
  const {
    data: rawData,
    isLoading: isRatingsRawReportLoading,
    error: rawDataError,
  } = useSystemNonDiscriminatoryAccessRawReport(isEditMode);
  const {
    data: processedData,
    isLoading: isProcessedRatingReportLoading,
    error: processedDataError,
  } = useSystemNonDiscriminatoryAccessProcessedReport(isEditMode);
  const { uid: reportUid, selectedMonth } = useParams();
  const dispatch = useAppDispatch();
  const { showSnackbar } = useESSnackbar();

  const reportData = isEditMode ? processedData : rawData;

  const defaultNonDiscriminatoryAccessValues = useMemo(() => {
    return {
      monthYear: getReportDefaultDate(
        reportData?.monthYear,
        selectedMonth,
        lastMonth
      ),
      providerGroup: reportData?.providerGroup ? reportData.providerGroup : '',
      cost: reportData?.cost ? reportData.cost : '',
      additionalCosts:
        reportData?.additionalCosts && reportData.additionalCosts.length > 0
          ? reportData.additionalCosts
          : [],
      validFrom: reportData?.validFrom ? new Date(reportData.validFrom) : null,
      validTo: reportData?.validTo ? new Date(reportData.validTo) : null,
      contractualBasis: reportData?.contractualBasis,
      reasoningDifferentCosts: reportData?.reasoningDifferentCosts
        ? reportData.reasoningDifferentCosts
        : '',
    };
  }, [reportData, selectedMonth]);

  const formMethods = useForm<ProcessedNonDiscriminatoryAccessReportFormData>({
    defaultValues: defaultNonDiscriminatoryAccessValues,
    mode: 'onTouched',
    resolver: yupResolver(systemNonDiscriminatoryAccessReportValidationSchema),
  });

  const {
    control,
    handleSubmit,
    formState: { isValid: isFormValid, isDirty: isFormDirty, errors },
    reset: resetForm,
    trigger,
  } = formMethods;

  const validFrom = useWatch({
    control,
    name: 'validFrom',
  });
  const validTo = useWatch({
    control,
    name: 'validTo',
  });

  const handleClose = useCallback(() => {
    resetForm();
    navigate(-1);
  }, [navigate, resetForm]);

  const isDisabled =
    isProcessedRatingReportLoading ||
    isRatingsRawReportLoading ||
    isEditing ||
    isCreating;

  const submitChargingHubRatingReportForm: SubmitHandler<ProcessedNonDiscriminatoryAccessReportFormData> =
    useCallback(
      (data) => {
        const {
          monthYear,
          providerGroup,
          cost,
          additionalCosts,
          validFrom,
          validTo,
          reasoningDifferentCosts,
          contractualBasis,
        } = data;

        if ((isEditMode && !reportUid) || !validFrom || !validTo) {
          return;
        }

        const successMessage = isEditMode
          ? 'updateSystemNonDiscriminatoryAccessReportSuccess'
          : 'createSystemNonDiscriminatoryAccessReportSuccess';

        const reportHandler = isEditMode
          ? editProcessedNonDiscriminatoryAccessReport({
              uid: reportUid as string,
              body: {
                monthYear,
                providerGroup,
                cost,
                additionalCosts,
                validFrom,
                validTo,
                reasoningDifferentCosts,
                contractualBasis,
              },
            })
          : createProcessedNonDiscriminatoryAccessReport({
              monthYear,
              providerGroup,
              cost,
              additionalCosts,
              validFrom,
              validTo,
              reasoningDifferentCosts,
              contractualBasis,
            });

        reportHandler
          .unwrap()
          .then(() => {
            showSnackbar('success', successMessage, 'systemReports');
            dispatch(
              systemProcessedReportsApi.util.invalidateTags([
                'SystemProcessedReports',
              ])
            );
            handleClose();
          })
          .catch((error) => {
            showSnackbar(
              'error',
              systemProcessedReportsApiErrors[error.data?.errorCode],
              'systemReports'
            );
          });
      },
      [
        createProcessedNonDiscriminatoryAccessReport,
        editProcessedNonDiscriminatoryAccessReport,
        handleClose,
        isEditMode,
        reportUid,
        dispatch,
        showSnackbar,
      ]
    );

  useEffect(() => {
    setIsNonDiscriminatoryAccessFormDirty &&
      setIsNonDiscriminatoryAccessFormDirty(isFormDirty);
    setIsDisabled(isDisabled);
  }, [
    isFormDirty,
    isDisabled,
    setIsNonDiscriminatoryAccessFormDirty,
    setIsDisabled,
  ]);

  useEffect(() => {
    resetForm(defaultNonDiscriminatoryAccessValues);
  }, [resetForm, defaultNonDiscriminatoryAccessValues]);

  useEffect(() => {
    if (rawDataError) {
      if ('data' in rawDataError) {
        if (rawDataError.status === 404) {
          return;
        }
        const response = rawDataError.data as ErrorApiResponse;
        response
          ? showSnackbar(
              'error',
              systemRawReportsApiErrors[response.errorCode],
              'systemReports'
            )
          : showSnackbar('error');
      } else {
        showSnackbar('error');
      }
    }
  }, [rawDataError, showSnackbar]);

  useEffect(() => {
    if (processedDataError) {
      if ('data' in processedDataError) {
        const response = processedDataError.data as ErrorApiResponse;
        response
          ? showSnackbar(
              'error',
              systemProcessedReportsApiErrors[response.errorCode],
              'systemReports'
            )
          : showSnackbar('error');
      } else {
        showSnackbar('error');
      }
      handleClose();
    }
  }, [processedDataError, showSnackbar, handleClose]);

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit(submitChargingHubRatingReportForm)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100vh - 124px)',
          justifyContent: 'space-between',
          overflow: 'hidden',
        }}
      >
        <SystemReportContainer
          title={t('nonDiscriminatoryAccess')}
          isFormValid={isFormValid}
          isFormDirty={isFormDirty}
          isLoading={isEditing || isCreating}
          activeStep={activeStep ?? 1}
          setActiveStep={setActiveStep}
          isEditMode={isEditMode}
        >
          <Grid
            container
            sx={{
              padding: 4,
              paddingTop: 4,
              paddingBottom: 4,
              alignItems: 'center',
            }}
          >
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight={500} color={'grey.600'}>
                {t('month') + ' *'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1">
                {reportData?.monthYear ? reportData.monthYear : '-'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="monthYear"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <MonthYearPicker
                      value={value}
                      onChange={onChange}
                      maxDate={lastMonth}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              padding: 4,
              paddingTop: 4,
              paddingBottom: 4,
              alignItems: 'center',
              marginBottom: 4,
            }}
          >
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight={500} color={'grey.600'}>
                {`${t('providerGroup')} [${t('countryCodeWithProviderId')}] *`}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1">
                {reportData?.providerGroup ? reportData.providerGroup : '-'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="providerGroup"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <ESTextField
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={Boolean(errors['providerGroup'])}
                      helperText={
                        Boolean(errors['providerGroup']) &&
                        t(`${errors['providerGroup']?.message}`)
                      }
                      disabled={isDisabled}
                      placeholder={
                        reportData?.providerGroup
                          ? reportData.providerGroup
                          : '-'
                      }
                      inputProps={{ maxLength: 5 }}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              padding: 4,
              paddingTop: 4,
              paddingBottom: 4,
              alignItems: 'center',
              marginBottom: 4,
            }}
          >
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight={500} color={'grey.600'}>
                {`${t('cost')} [${t('ctPerKwhWithoutVat')}] *`}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1">
                {reportData?.cost ? reportData.cost : '-'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="cost"
                render={({ field: { onChange, onBlur, value } }) => (
                  <ESTextField
                    placeholder={reportData?.cost ? reportData.cost : '-'}
                    value={value}
                    disabled={isDisabled}
                    onChange={(event) => {
                      const inputValue = event?.target.value;
                      // this regex matches a string that consists entirely of one or more digits, with no other characters before or after
                      if (inputValue === '' || /^\d+$/.test(inputValue ?? '')) {
                        onChange(inputValue);
                      }
                    }}
                    onBlur={onBlur}
                    error={Boolean(errors['cost'])}
                    helperText={
                      Boolean(errors['cost']) && t(`${errors['cost']?.message}`)
                    }
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              paddingLeft: 4,
              paddingRight: 4,
              paddingTop: 4,
              paddingBottom: 4,
              alignItems: 'center',
            }}
          >
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight={500} color={'grey.600'}>
                {t('validFrom') + ' *'}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1">
                {reportData?.validFrom
                  ? formatDateTime(reportData.validFrom)
                  : '-'}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Controller
                control={control}
                name="validFrom"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <ESDateTimePicker
                      value={value}
                      disabled={isDisabled}
                      onChange={(value) => {
                        onChange(value);
                        if (isValid(value) && isValid(validTo)) {
                          trigger('validTo');
                        }
                      }}
                      onBlur={onBlur}
                      error={Boolean(errors['validFrom'])}
                      helperText={
                        Boolean(errors['validFrom']) &&
                        t(`${errors['validFrom']?.message}`)
                      }
                    />
                  );
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              paddingLeft: 4,
              paddingRight: 4,
              paddingTop: 4,
              paddingBottom: 4,
              alignItems: 'center',
            }}
          >
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight={500} color={'grey.600'}>
                {t('validTo') + ' *'}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1">
                {reportData?.validTo ? formatDateTime(reportData.validTo) : '-'}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Controller
                control={control}
                name="validTo"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <ESDateTimePicker
                      value={value}
                      disabled={isDisabled}
                      onChange={onChange}
                      onBlur={onBlur}
                      minDateTime={validFrom || undefined}
                      error={Boolean(errors['validTo'])}
                      helperText={
                        Boolean(errors['validTo']) &&
                        t(`${errors['validTo']?.message}`)
                      }
                    />
                  );
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              padding: 4,
              paddingTop: 4,
              paddingBottom: 4,
              alignItems: 'center',
              marginBottom: 4,
            }}
          >
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight={500} color={'grey.600'}>
                {t('reasoning')}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1">
                {reportData?.reasoningDifferentCosts
                  ? reportData.reasoningDifferentCosts
                  : '-'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="reasoningDifferentCosts"
                render={({ field: { onChange, onBlur, value } }) => (
                  <ESTextField
                    placeholder={
                      reportData?.reasoningDifferentCosts
                        ? reportData.reasoningDifferentCosts
                        : '-'
                    }
                    value={value}
                    disabled={isDisabled}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={Boolean(errors['reasoningDifferentCosts'])}
                    helperText={
                      Boolean(errors['reasoningDifferentCosts']) &&
                      t(`${errors['reasoningDifferentCosts']?.message}`)
                    }
                    inputProps={{ maxLength: 255 }}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              padding: 4,
              paddingTop: 4,
              paddingBottom: 4,
              alignItems: 'center',
              marginBottom: 4,
            }}
          >
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight={500} color={'grey.600'}>
                {t('contractualBasis') + ' *'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1">
                {reportData?.contractualBasis
                  ? t(`${reportData.contractualBasis}`)
                  : '-'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="contractualBasis"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <ESVirtualizedAutocomplete
                      label=""
                      placeholder={
                        reportData?.contractualBasis
                          ? t(reportData.contractualBasis)
                          : '-'
                      }
                      disabled={isDisabled}
                      value={{
                        label: value ? t(value) : '',
                        value: value ?? '',
                      }}
                      options={nonDiscriminatoryAccessReportContractualBasisModel.map(
                        (curr) => ({
                          label: curr ? t(curr) : '',
                          value: curr,
                        })
                      )}
                      noOptionsText={t(
                        'createSystemNonDiscriminatoryAccessContractualBasisNoOptions'
                      )}
                      onBlur={onBlur}
                      blurOnSelect
                      onChange={(_, value) => {
                        if (Array.isArray(value)) {
                          return;
                        }
                        if (value) {
                          onChange(value.value ?? '');
                        }
                      }}
                      error={Boolean(errors['contractualBasis'])}
                      helperText={
                        Boolean(errors['contractualBasis']) &&
                        t(`${errors['contractualBasis']?.message}`)
                      }
                    />
                  );
                }}
              />
            </Grid>
          </Grid>

          <AdditionalCosts isDisabled={isDisabled} />
        </SystemReportContainer>
      </form>
    </FormProvider>
  );
};
