import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Box, Button } from '@mui/material';
import { ProcessedAdHocTariffReportFormData } from './ChargingHubAdHocTariffReportForm';
import { AdditionalCost } from './AdditionalCost';
import { useEffect, useRef, useState } from 'react';

interface AdditionalCostsProps {
  isDisabled: boolean;
}

export const AdditionalCosts: React.FC<AdditionalCostsProps> = ({
  isDisabled,
}) => {
  const { t } = useTranslation('chargingHubReports');
  const defaultAdditionalCost = {
    description: '',
    cost: '',
    isNew: true,
  };

  const { control } = useFormContext<ProcessedAdHocTariffReportFormData>();

  const {
    fields: additionalCosts,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'additionalCosts',
  });

  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(false);

  const addCostButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (shouldScrollToBottom && addCostButtonRef?.current) {
      addCostButtonRef.current.scrollIntoView({ behavior: 'smooth' });
      setShouldScrollToBottom(false);
    }
  }, [shouldScrollToBottom]);

  return (
    <Box p={3}>
      {additionalCosts.map((additionalCost, index) => (
        <AdditionalCost
          key={additionalCost.id}
          additionalCostIndex={index}
          isDisabled={isDisabled}
          onRemove={remove}
          additionalCost={additionalCost}
        />
      ))}

      <Button
        ref={addCostButtonRef}
        disabled={isDisabled}
        onClick={() => {
          append(defaultAdditionalCost);
          setShouldScrollToBottom(true);
        }}
        variant="text"
        startIcon={<AddRoundedIcon />}
      >
        {t('addCostButton')}
      </Button>
    </Box>
  );
};
