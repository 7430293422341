import { useAppDispatch } from '@energy-stacks/store';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ChargingStationReportContainer } from '../ChargingStationReportContainer';
import { Box, Grid, Typography } from '@mui/material';
import {
  ESDateTimePicker,
  ESVirtualizedAutocomplete,
  showSnackbar,
} from '@energy-stacks/core/ui';
import { ChargingStationReportContainerHeader } from '../ChargingStationReportContainerHeader';
import { editChargingStationDefectReportSchema } from './editChargingStationDefectReportSchema';
import { createChargingStationDefectReportSchema } from './createChargingStationDefectReportSchema';
import {
  activitiesModel,
  additionalInformationModel,
  chargingStationReportsApi,
  chargingStationsReportsApiErrors,
  statusModel,
  typeModel,
  useCreateProcessedDefectReportMutation,
  useEditProcessedDefectReportMutation,
} from '@energy-stacks/obelis/feature-charging-station-reports-data';
import {
  formatDateTime,
  RefetchOnError,
  toPayloadDateObelis,
} from '@energy-stacks/shared';
import { useRawDefectReport } from './useRawDefectReport';
import { useProcessedDefectReport } from './useProcessedDefectReport';
import { useChargingStationDetails } from '../useChargingStationDetails';

interface DefectReportFormData {
  evseId: { value: string; label: string };
  type: { value: string; label: string };
  status: { value: string; label: string };
  activities: { value: string; label: string };
  additionalInformation: {
    value: string;
    label: string;
  };
  emergenceDate: Date | null;
  forecastDate: Date | null;
  fixDate: Date | null;
}

const additionalInformationModelToDtoMap: Record<string, string> = {
  dangerousMalFunction: 'DANGEROUS_MALFUNCTION',
  usageImpossible: 'USAGE_IMPOSSIBLE',
  renewalNecessary: 'RENEWAL_NECESSARY',
};

const activitiesModelToDtoMap: Record<string, string> = {
  remoteAccess: 'REMOTE_ACCESS',
  onSite: 'ON_SITE',
};

const statusModelToDtoMap: Record<string, string> = {
  technicianInformed: 'TECHNICIAN_INFORMED',
  technicianOnTheWay: 'TECHNICIAN_ON_THE_WAY',
  errorSolved: 'ERROR_SOLVED',
};

const typeModelToDtoMap: Record<string, string> = {
  damage: 'DAMAGE',
  hardwareFault: 'HARDWARE_FAULT',
  softwareFault: 'SOFTWARE_FAULT',
  networkFailure: 'NETWORK_FAILURE',
  powerFailure: 'POWER_FAILURE',
  routeClosure: 'ROUTE_CLOSURE',
  siteClosure: 'SITE_CLOSURE',
  other: 'OTHER',
};

export const ChargingStationDefectReportForm = ({
  setIsRatingFormDirty,
  setIsDisabled,
  activeStep,
  setActiveStep,
  isEditMode,
}: {
  setIsRatingFormDirty: (isRatingFormDirty: boolean) => void;
  setIsDisabled: (isDisabled: boolean) => void;
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
  isEditMode: boolean;
}) => {
  const { t } = useTranslation('chargingStationReports');
  const navigate = useNavigate();
  const [createProcessedDefectReport, { isLoading: isCreating }] =
    useCreateProcessedDefectReportMutation();
  const [editProcessedDefectReport, { isLoading: isEditing }] =
    useEditProcessedDefectReportMutation();
  const { id } = useParams<{ id: string }>();
  const { rawReport, isLoading: isRawReportLoading } =
    useRawDefectReport(isEditMode);
  const { processedReport, isLoading: isProcessedReportLoading } =
    useProcessedDefectReport(isEditMode);

  const reportData = isEditMode ? processedReport : rawReport;
  const tableValueHeader = isEditMode ? 'currentValue' : 'originalValue';

  const {
    chargingStationDetails,
    isError,
    isLoading: isDetailsLoading,
    refetch,
  } = useChargingStationDetails(isEditMode);

  const chargingPointEvsesOptions = chargingStationDetails?.evses?.map(
    (evse) => {
      return {
        label: evse.evseId,
        value: evse.evseId,
      };
    }
  );

  const dispatch = useAppDispatch();

  const defaultValues = useMemo(() => {
    return {
      evseId: {
        label: reportData?.evseId || '',
        value: reportData?.evseId || '',
      },
      type: {
        label: isEditMode ? reportData?.type ?? '' : '',
        value: isEditMode ? reportData?.type ?? '' : '',
      },
      status: {
        label: isEditMode ? reportData?.status ?? '' : '',
        value: isEditMode ? reportData?.status ?? '' : '',
      },
      activities: {
        label: isEditMode ? reportData?.activities ?? '' : '',
        value: isEditMode ? reportData?.activities ?? '' : '',
      },
      additionalInformation: {
        label: isEditMode ? reportData?.additionalInformation ?? '' : '',
        value: isEditMode ? reportData?.additionalInformation ?? '' : '',
      },
      emergenceDate:
        isEditMode && reportData?.emergenceDate
          ? new Date(reportData?.emergenceDate)
          : null,
      forecastDate:
        isEditMode && reportData?.forecastDate
          ? new Date(reportData?.forecastDate)
          : null,
      fixDate:
        isEditMode && reportData?.fixDate
          ? new Date(reportData?.fixDate)
          : null,
    };
  }, [isEditMode, reportData]);

  const validationSchema = isEditMode
    ? editChargingStationDefectReportSchema
    : createChargingStationDefectReportSchema;

  const formMethods = useForm<DefectReportFormData>({
    defaultValues,
    mode: 'onTouched',
    resolver: yupResolver(validationSchema),
  });

  const {
    handleSubmit,
    formState: { isValid: isFormValid, isDirty: isFormDirty, errors },
    reset: resetForm,
    control,
  } = formMethods;

  const handleClose = useCallback(() => {
    resetForm();
    navigate(-1);
  }, [navigate, resetForm]);

  const isDisabled = isCreating || isDetailsLoading || isEditing;

  useEffect(() => {
    setIsRatingFormDirty(isFormDirty);
    setIsDisabled(isDisabled);
  }, [isFormDirty, setIsRatingFormDirty, setIsDisabled, isDisabled]);

  const onSubmit: SubmitHandler<DefectReportFormData> = (data) => {
    const evse = chargingStationDetails?.evses.find((evse) => {
      return evse.evseId === data.evseId.value;
    });

    const chargingHubId =
      reportData?.chargingHubUid || chargingStationDetails?.chargingHub.id;

    if (
      !evse?.chargingPointId ||
      !id ||
      !chargingHubId ||
      !data.emergenceDate
    ) {
      return;
    }

    const createBody = {
      uid: reportData?.uid || null,
      chargingHubUid: chargingHubId,
      identityKey: id,
      chargingPointUid: evse.chargingPointId,
      type: typeModelToDtoMap[data.type.value],
      status: statusModelToDtoMap[data.status.value],
      activities: activitiesModelToDtoMap[data.activities.value],
      additionalInformation:
        additionalInformationModelToDtoMap[data.additionalInformation.value],
      emergenceDate: toPayloadDateObelis(data.emergenceDate),
      forecastDate: data.forecastDate
        ? toPayloadDateObelis(data.forecastDate)
        : null,
      fixDate: data.fixDate ? toPayloadDateObelis(data.fixDate) : null,
    };
    const editBody = {
      type: typeModelToDtoMap[data.type.value],
      status: statusModelToDtoMap[data.status.value],
      activities: activitiesModelToDtoMap[data.activities.value],
      additionalInformation:
        additionalInformationModelToDtoMap[data.additionalInformation.value],
      emergenceDate: data.emergenceDate
        ? toPayloadDateObelis(data.emergenceDate)
        : null,
      forecastDate: data.forecastDate
        ? toPayloadDateObelis(data.forecastDate)
        : null,
      fixDate: data.fixDate ? toPayloadDateObelis(data.fixDate) : null,
    };
    const successMessage = isEditMode
      ? 'editChargingStationSuccess'
      : 'addChargingStationSuccess';
    const reportHandler = isEditMode
      ? editProcessedDefectReport({ body: editBody, uid: id ?? '' })
      : createProcessedDefectReport(createBody);

    reportHandler
      .unwrap()
      .then(() => {
        showSnackbar('success', successMessage, 'chargingStationReports');
        dispatch(
          chargingStationReportsApi.util.invalidateTags([
            'ChargingStationReports',
          ])
        );
        handleClose();
      })
      .catch((error) => {
        showSnackbar(
          'error',
          chargingStationsReportsApiErrors[error.data?.errorCode],
          'chargingStationReports'
        );
      });
  };

  useEffect(() => {
    resetForm(defaultValues);
  }, [defaultValues, resetForm]);

  if (isError) {
    return (
      <Box sx={{ mt: 50 }}>
        <RefetchOnError onRefetch={refetch} />
      </Box>
    );
  }

  if (isRawReportLoading || isProcessedReportLoading) {
    return null;
  }

  return (
    <FormProvider {...formMethods}>
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <ChargingStationReportContainer
          title="chargingPointDefect"
          isFormValid={isFormValid}
          isFormDirty={isFormDirty}
          isLoading={isCreating || isEditing}
          activeStep={activeStep ?? 1}
          setActiveStep={setActiveStep}
          isEditMode={isEditMode}
        >
          {!isEditMode ? (
            <Grid
              container
              sx={{
                padding: 4,
                paddingTop: 4,
                paddingBottom: 4,
                alignItems: 'center',
              }}
            >
              <Grid item xs={4} mr={6}>
                <Controller
                  control={control}
                  name="evseId"
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value } }) => {
                    return (
                      <ESVirtualizedAutocomplete
                        label={t('evseID')}
                        disabled={isDisabled}
                        required
                        value={{
                          label: value.label,
                          value: value.value,
                        }}
                        options={chargingPointEvsesOptions || []}
                        onBlur={onBlur}
                        blurOnSelect
                        onChange={(_, value) => {
                          if (Array.isArray(value)) {
                            return;
                          }
                          if (value) {
                            onChange({
                              value: value.value,
                              label: value.label,
                            });
                          }
                        }}
                        noOptionsText={t('noEvses')}
                        error={Boolean(errors['evseId'])}
                        helperText={
                          Boolean(errors['evseId']) &&
                          t(`${errors['evseId']?.value?.message}`)
                        }
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          ) : null}
          <ChargingStationReportContainerHeader
            tableValueHeader={tableValueHeader}
          />
          <Grid
            container
            sx={{
              padding: 4,
              paddingTop: 4,
              paddingBottom: 4,
              alignItems: 'center',
            }}
          >
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight={500} color={'grey.600'}>
                {t('type') + ' *'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              {reportData ? t(reportData.type) : '-'}
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="type"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <ESVirtualizedAutocomplete
                      label=""
                      disabled={isDisabled}
                      required
                      value={{
                        label: value.label ? t(value.label) : '',
                        value: value.value || '',
                      }}
                      options={typeModel.map((option) => ({
                        label: t(option),
                        value: option,
                      }))}
                      onBlur={onBlur}
                      blurOnSelect
                      onChange={(_, value) => {
                        if (Array.isArray(value)) {
                          return;
                        }
                        if (value) {
                          onChange({
                            value: value.value,
                            label: value.label,
                          });
                        }
                      }}
                      noOptionsText={t('type')}
                      error={Boolean(errors['type'])}
                      helperText={
                        Boolean(errors['type']) &&
                        t(`${errors['type']?.value?.message}`)
                      }
                      placeholder={reportData?.type ? t(reportData?.type) : '-'}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              padding: 4,
              paddingTop: 4,
              paddingBottom: 4,
              alignItems: 'center',
            }}
          >
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight={500} color={'grey.600'}>
                {t('additionalInformation')}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              {reportData?.additionalInformation
                ? t(reportData?.additionalInformation)
                : '-'}
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="additionalInformation"
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <ESVirtualizedAutocomplete
                      label=""
                      disabled={isDisabled}
                      value={{
                        label: value.label ? t(value.label) : '',
                        value: value.value || '',
                      }}
                      options={additionalInformationModel.map((option) => ({
                        label: t(option),
                        value: option,
                      }))}
                      onBlur={onBlur}
                      blurOnSelect
                      onChange={(_, value) => {
                        if (Array.isArray(value)) {
                          return;
                        }
                        if (value) {
                          onChange({
                            value: value.value,
                            label: value.label,
                          });
                        }
                      }}
                      noOptionsText={t('additionalInformation')}
                      error={Boolean(errors['additionalInformation'])}
                      helperText={
                        Boolean(errors['additionalInformation']) &&
                        t(`${errors['additionalInformation']?.value?.message}`)
                      }
                      placeholder={
                        reportData?.additionalInformation
                          ? t(reportData?.additionalInformation)
                          : '-'
                      }
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              padding: 4,
              paddingTop: 4,
              paddingBottom: 4,
              alignItems: 'center',
            }}
          >
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight={500} color={'grey.600'}>
                {t('status') + ' *'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              {reportData ? t(reportData.status) : '-'}
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="status"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <ESVirtualizedAutocomplete
                      label=""
                      disabled={isDisabled}
                      required
                      value={{
                        label: value.label ? t(value.label) : '',
                        value: value.value || '',
                      }}
                      options={statusModel.map((option) => ({
                        label: t(option),
                        value: option,
                      }))}
                      onBlur={onBlur}
                      blurOnSelect
                      onChange={(_, value) => {
                        if (Array.isArray(value)) {
                          return;
                        }
                        if (value) {
                          onChange({
                            value: value.value,
                            label: value.label,
                          });
                        }
                      }}
                      noOptionsText={t('status')}
                      error={Boolean(errors['status'])}
                      helperText={
                        Boolean(errors['status']) &&
                        t(`${errors['status']?.value?.message}`)
                      }
                      placeholder={
                        reportData?.status ? t(reportData?.status) : '-'
                      }
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              padding: 4,
              paddingTop: 4,
              paddingBottom: 4,
              alignItems: 'center',
            }}
          >
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight={500} color={'grey.600'}>
                {t('activities') + ' *'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              {reportData ? t(reportData.activities) : '-'}
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="activities"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <ESVirtualizedAutocomplete
                      label=""
                      disabled={isDisabled}
                      required
                      value={{
                        label: value.label ? t(value.label) : '',
                        value: value.value || '',
                      }}
                      options={activitiesModel.map((option) => ({
                        label: t(option),
                        value: option,
                      }))}
                      onBlur={onBlur}
                      blurOnSelect
                      onChange={(_, value) => {
                        if (Array.isArray(value)) {
                          return;
                        }
                        if (value) {
                          onChange({
                            value: value.value,
                            label: value.label,
                          });
                        }
                      }}
                      noOptionsText={t('activities')}
                      error={Boolean(errors['activities'])}
                      helperText={
                        Boolean(errors['activities']) &&
                        t(`${errors['activities']?.value?.message}`)
                      }
                      placeholder={
                        reportData?.activities ? t(reportData?.activities) : '-'
                      }
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              padding: 4,
              paddingTop: 4,
              paddingBottom: 4,
              alignItems: 'center',
            }}
          >
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight={500} color={'grey.600'}>
                {t('emergenceDate') + ' *'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              {reportData?.emergenceDate
                ? formatDateTime(reportData?.emergenceDate || '')
                : '-'}
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="emergenceDate"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <ESDateTimePicker
                      required
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={Boolean(errors['emergenceDate'])}
                      helperText={
                        Boolean(errors['emergenceDate']) &&
                        t(`${errors['emergenceDate']?.message}`)
                      }
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              padding: 4,
              paddingTop: 4,
              paddingBottom: 4,
              alignItems: 'center',
            }}
          >
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight={500} color={'grey.600'}>
                {t('forecastDate')}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              {reportData?.forecastDate
                ? formatDateTime(reportData?.forecastDate || '')
                : '-'}
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="forecastDate"
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <ESDateTimePicker
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={Boolean(errors['forecastDate'])}
                      helperText={
                        Boolean(errors['forecastDate']) &&
                        t(`${errors['forecastDate']?.message}`)
                      }
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              padding: 4,
              paddingTop: 4,
              paddingBottom: 4,
              alignItems: 'center',
            }}
          >
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight={500} color={'grey.600'}>
                {t('fixDate')}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              {reportData?.fixDate
                ? formatDateTime(reportData?.fixDate || '')
                : '-'}
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="fixDate"
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <ESDateTimePicker
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={Boolean(errors['fixDate'])}
                      helperText={
                        Boolean(errors['fixDate']) &&
                        t(`${errors['fixDate']?.message}`)
                      }
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </ChargingStationReportContainer>
      </form>
    </FormProvider>
  );
};
