import { appendZToDateString } from '@energy-stacks/shared';
import { additionalCostsReportDtoToModelNormalizer } from '../../normalizers/additionalCostsReportToPayload';
import { ProcessedAdHocTariffReportDto } from '../processedAdHocTariffReportDto';
import { ProcessedAdHocTariffReportModel } from '../processedAdHocTariffReportModel';

export const adHocTariffProcessedReportNormalizer = (
  adHocTariffReportDto: ProcessedAdHocTariffReportDto
): ProcessedAdHocTariffReportModel => {
  const {
    uid,
    monthYear,
    chargingHubUid,
    cost,
    additionalCosts,
    validFrom,
    validTo,
  } = adHocTariffReportDto;

  return {
    uid: uid,
    monthYear: monthYear,
    chargingHubUid: chargingHubUid,
    cost: String(cost),
    additionalCosts: additionalCostsReportDtoToModelNormalizer(
      additionalCosts ?? []
    ),
    validFrom: appendZToDateString(validFrom),
    validTo: appendZToDateString(validTo),
  };
};
