import { Dispatch, SetStateAction, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ObelisRoutes } from '@energy-stacks/obelis/shared';
import { Box, Stack } from '@mui/material';
import {
  PaginationState,
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  ESAddItemButton,
  ESMenuItem,
  ESTable,
  ESTableBody,
  ESTableHead,
  ESTablePagination,
  ESTableWrapper,
  useFitRows,
} from '@energy-stacks/core/ui';
import {
  ChargingHubModel,
  ChargingHubsModel,
} from '@energy-stacks/obelis/feature-charging-hubs-data';
import {
  ESTooltip,
  MoreOptionsMenu,
  NoTableData,
  TableSearchField,
  formatDateTime,
} from '@energy-stacks/shared';
import { DeleteChargingHubDialog } from './DeleteChargingHubDialog';

const MAX_CHARACTERS_LENGTH = 45;

interface ChargingHubsTableProps {
  chargingHubs: ChargingHubsModel | undefined;
  tableId?: string;
  testId?: string;
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  onPaginationChange: Dispatch<SetStateAction<PaginationState>>;
  sorting: SortingState;
  pagination: PaginationState;
  onSortingChange: Dispatch<SetStateAction<SortingState>>;
}

export const ChargingHubsTable: React.FC<ChargingHubsTableProps> = ({
  chargingHubs,
  testId = 'chargingHubs',
  pagination,
  sorting,
  onPaginationChange,
  onSortingChange,
  search,
  setSearch,
}) => {
  const { t } = useTranslation('chargingHubs');
  const navigate = useNavigate();
  const [chargingHubToDelete, setChargingHubToDelete] = useState<
    ChargingHubModel | undefined
  >(undefined);

  const columnHelper = createColumnHelper<ChargingHubModel>();

  const columns = [
    columnHelper.accessor('id', {
      header: () => t('id'),
      footer: (props) => props.column.id,
      cell: (info) => info.getValue() || '-',
      enableGlobalFilter: false,
      enableSorting: true,
    }),
    columnHelper.accessor('name', {
      header: () => t('name'),
      footer: (props) => props.column.id,
      cell: (info) => info.getValue() || '-',
      enableGlobalFilter: false,
      enableSorting: false,
    }),
    columnHelper.accessor('address', {
      header: () => t('address'),
      footer: (props) => props.column.id,
      cell: (info) => info.getValue() || '-',
      enableGlobalFilter: false,
      enableSorting: false,
    }),
    columnHelper.accessor('city', {
      header: () => t('city'),
      footer: (props) => props.column.id,
      cell: (info) => info.getValue(),
      enableGlobalFilter: false,
      enableSorting: false,
    }),
    columnHelper.accessor('lastModifiedDate', {
      header: () => t('lastUpdated'),
      footer: (props) => props.column.id,
      cell: (info) => (info.getValue() ? formatDateTime(info.getValue()) : '-'),
      enableGlobalFilter: false,
      enableSorting: false,
    }),
    columnHelper.accessor('chargingStations', {
      header: () => t('chargingStations'),
      footer: (props) => props.column.id,
      cell: (info) => info.getValue() || '-',
      enableGlobalFilter: false,
      enableSorting: false,
    }),
    columnHelper.display({
      id: 'actions',
      header: () => t('actions'),
      cell: (info) => (
        <MoreOptionsMenu testId={info.row.original.id + testId}>
          <ESMenuItem
            testId={`${info.row.original.id}MenuItemEditButton`}
            onClick={() => navigate(`${info.row.original.id}/edit`)}
          >
            {t('editMenuItem')}
          </ESMenuItem>
          <ESMenuItem
            testId={`${info.row.original.id}MenuItemDeleteButton`}
            onClick={() => setChargingHubToDelete(info.row.original)}
          >
            {t('deleteMenuItem')}
          </ESMenuItem>
        </MoreOptionsMenu>
      ),
    }),
  ];

  const instance = useReactTable({
    data: chargingHubs?.chargingHubs ?? [],
    columns,
    state: {
      pagination,
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange,
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange,
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    pageCount: chargingHubs?.totalPages,
    enableColumnResizing: false,
  });

  const { rowsPerPageOptions } = useFitRows(
    instance,
    chargingHubs?.totalElements ?? 0
  );

  const filteredRows = instance.getFilteredRowModel().rows;
  const rows = instance.getRowModel().rows;
  const hasRows = rows.length !== 0;

  const onSearchChange = useCallback(
    (value: string) => {
      const trimmedNewValue = value.trim();
      setSearch(trimmedNewValue);
    },
    [setSearch]
  );

  return (
    <>
      <Box display="flex" mb={6}>
        <Stack direction="row" gap={3} alignItems="center">
          <ESTooltip title={t('chargingHubsSearchPlaceholder')}>
            <TableSearchField
              testId={testId}
              value={search}
              onChange={(val) => {
                onSearchChange(val);
              }}
              tableInstance={instance}
              debounce={500}
              placeholder={t('chargingHubsSearchPlaceholder')}
              maxNumberOfCharacters={MAX_CHARACTERS_LENGTH}
            />
          </ESTooltip>
        </Stack>
        <Box
          sx={{ marginLeft: 'auto', pl: 2 }}
          display="flex"
          alignItems="center"
        >
          <Box>
            <ESAddItemButton
              title={t('addChargingHubButton')}
              onClick={() =>
                navigate(
                  `${ObelisRoutes.ChargingHubs}/${ObelisRoutes.AddChargingHub}`
                )
              }
            />
          </Box>
        </Box>
      </Box>
      <ESTableWrapper>
        <ESTable>
          <ESTableHead testId={testId} instance={instance} />
          <ESTableBody
            testId={testId}
            rows={rows}
            onRowClick={(row) => {
              navigate(
                `${row.original.id}/${ObelisRoutes.ChargingHubDetailsGeneralTab}`
              );
            }}
          />
        </ESTable>
        {!hasRows ? <NoTableData message={t('noChargingHubs')} /> : null}
        <ESTablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          instance={instance}
          count={chargingHubs?.totalElements || filteredRows.length}
        />
      </ESTableWrapper>
      {chargingHubToDelete && (
        <DeleteChargingHubDialog
          chargingHubDetails={{
            chargingHubId: chargingHubToDelete.id,
            chargingHubName: chargingHubToDelete.name,
            assignedChargingStationsCount: chargingHubToDelete.chargingStations,
          }}
          onClose={() => setChargingHubToDelete(undefined)}
        />
      )}
    </>
  );
};
