import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { Radio, FormControlLabel, useTheme } from '@mui/material';
import { SystemReportTypeModel } from '@energy-stacks/obelis/feature-system-reports-data';

export const SystemReportTypeRadioButton = ({
  option,
  selectedValue,
}: {
  option: SystemReportTypeModel;
  selectedValue: SystemReportTypeModel | null;
}) => {
  const { t } = useTranslation('systemReports');
  const isSelected = option === selectedValue;
  const { palette } = useTheme();

  const containerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid #ccc',
    borderRadius: '12px',
    cursor: 'pointer',
    backgroundColor: isSelected ? palette.primary.light : 'transparent',
  };

  return (
    <div style={containerStyle}>
      <FormControlLabel
        value={option}
        control={<Radio />}
        label={t(option)}
        sx={{
          marginLeft: '-12px',
          padding: '8px 16px',
        }}
      />
    </div>
  );
};
