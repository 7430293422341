import { ESDialogTitle } from '@energy-stacks/core/ui';
import {
  ConfirmCloseDialog,
  useConfirmCloseDialog,
} from '@energy-stacks/shared';
import { Dialog, IconButton, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { IconX } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { AddChargingHubForm } from './AddChargingHubForm';
import { useDispatch } from 'react-redux';
import { addChargingHubSteps } from './addChargingHubSteps';
import { resetStepper, useAppSelector } from '@energy-stacks/store';

export const AddChargingHub = () => {
  const dispatch = useDispatch();
  const [isDialogOpen, setIsDialogOpen] = useState(true);
  const [t] = useTranslation('chargingHubs');
  const activeStep = useAppSelector((state) => state.stepper.stepperActiveStep);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const { palette } = useTheme();

  useEffect(() => {
    return () => {
      dispatch(resetStepper());
    };
  }, [dispatch]);

  const {
    isConfirmCloseDialogOpen,
    handleOpenConfirmDialog,
    handleStay,
    handleLeaveWithoutSaving,
  } = useConfirmCloseDialog(isFormDirty);

  return (
    <>
      <Dialog
        open={isDialogOpen}
        onClose={handleOpenConfirmDialog}
        maxWidth="lg"
        fullWidth
      >
        <ESDialogTitle
          data-testid="addChargingHubDialogTitle"
          sx={{
            borderBottom: `1px solid ${palette.grey[300]}`,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            pb: 4,
          }}
          justifyContent="space-between"
        >
          {t('addChargingHub')}
          <IconButton
            data-testid="addStationCloseModalButton"
            onClick={handleOpenConfirmDialog}
          >
            <IconX />
          </IconButton>
        </ESDialogTitle>
        <AddChargingHubForm
          display={addChargingHubSteps[activeStep].name}
          onSetIsDialogOpen={setIsDialogOpen}
          activeStep={activeStep}
          onFormDirty={setIsFormDirty}
        />
      </Dialog>
      <ConfirmCloseDialog
        open={isConfirmCloseDialogOpen}
        onStay={handleStay}
        onLeaveWithoutSaving={handleLeaveWithoutSaving}
        testId="addChargingHub"
      />
    </>
  );
};
