export const reasonsModel = [
  'emergencyStop',
  'evDisconnected',
  'hardReset',
  'local',
  'other',
  'powerloss',
  'reboot',
  'remote',
  'softReset',
  'unlockCommand',
  'deauthorized',
] as const;

export type ReasonModel = (typeof reasonsModel)[number];
