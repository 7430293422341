import { ChargingSessionDto } from '../dtos/chargingSessionDto';
import { ReasonDto } from '../dtos/reasonDto';
import { TariffTypeDto } from '../dtos/tariffTypeDto';
import { ChargingSessionModel } from '../models/chargingSessionModel';
import { ReasonModel } from '../models/reasonModel';
import { TariffTypeModel } from '../models/tariffTypeModel';
import { chargingPeriodNormalizer } from './chargingPeriodNormalizer';

export const chargingSessionsNormalizer = (
  chargingSession: ChargingSessionDto
): ChargingSessionModel => {
  return {
    txnId: chargingSession.chargingSessionUid,
    sessionStart: chargingSession.start,
    sessionStop: chargingSession.end,
    totalTime: chargingSession.totalTime,
    totalEnergy: chargingSession.totalEnergy,
    emaId: chargingSession.emaId ? chargingSession.emaId : null,
    evseId: chargingSession?.evseId,
    totalCost: chargingSession.totalCost,
    totalParkingTime: chargingSession.totalParkingTime,
    reason: chargingSession.reason
      ? reasonsDtoToModelMap[chargingSession.reason]
      : null,
    tariffType: tariffTypesDtoToModelMap[chargingSession.tariffType],
    chargingPeriods: chargingSession.chargingPeriods
      ? chargingSession.chargingPeriods.map((chargingPeriod) =>
          chargingPeriodNormalizer(chargingPeriod)
        )
      : null,
  };
};

export const reasonsDtoToModelMap: Record<ReasonDto, ReasonModel> = {
  EMERGENCY_STOP: 'emergencyStop',
  EV_DISCONNECTED: 'evDisconnected',
  HARDRESET: 'hardReset',
  LOCAL: 'local',
  OTHER: 'other',
  POWERLOSS: 'powerloss',
  REBOOT: 'reboot',
  REMOTE: 'remote',
  SOFT_RESET: 'softReset',
  UNLOCK_COMMAND: 'unlockCommand',
  DEAUTHORIZED: 'deauthorized',
} as const;

export const tariffTypesDtoToModelMap: Record<TariffTypeDto, TariffTypeModel> =
  {
    ADD_HOC_PAYMENT: 'addHocPayment',
    PROFILE_CHEAP: 'profileCheap',
    PROFILE_FAST: 'profileFast',
    PROFILE_GREEN: 'profileGreen',
    REGULAR: 'regular',
    NON_BILLABLE: 'nonBillable',
  } as const;

export const reasonsModelToDtoMap: Record<ReasonModel, ReasonDto> = {
  emergencyStop: 'EMERGENCY_STOP',
  evDisconnected: 'EV_DISCONNECTED',
  hardReset: 'HARDRESET',
  local: 'LOCAL',
  other: 'OTHER',
  powerloss: 'POWERLOSS',
  reboot: 'REBOOT',
  remote: 'REMOTE',
  softReset: 'SOFT_RESET',
  unlockCommand: 'UNLOCK_COMMAND',
  deauthorized: 'DEAUTHORIZED',
} as const;

export const tariffTypesModelToDtoMap: Record<TariffTypeModel, TariffTypeDto> =
  {
    addHocPayment: 'ADD_HOC_PAYMENT',
    profileCheap: 'PROFILE_CHEAP',
    profileFast: 'PROFILE_FAST',
    profileGreen: 'PROFILE_GREEN',
    regular: 'REGULAR',
    nonBillable: 'NON_BILLABLE',
  } as const;
