import {
  ESButton,
  ESDialogActions,
  ESTextField,
  useESSnackbar,
} from '@energy-stacks/core/ui';
import { EditableMap } from '@energy-stacks/shared';
import { Box, Grid } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { addChargingStationValidationScheme } from './addChargingStationValidationScheme';
import { AddChargingStationChargingHubSelect } from './AddChargingStationChargingHubSelect';
import {
  chargingStationsApiErrors,
  useAddChargingStationMutation,
} from '@energy-stacks/obelis/feature-charging-stations-data';

type AddChargingStationFormData = {
  id: string;
  name: string;
  chargingHub: {
    value: string;
    label: string;
  };
  vendor?: string;
  model?: string;
  coordinates: {
    latitude: string;
    longitude: string;
  };
};

const addChargingStationDefaultValues = {
  id: '',
  name: '',
  chargingHub: {
    label: '',
    value: '',
  },
  vendor: '',
  model: '',
  coordinates: {
    longitude: '',
    latitude: '',
  },
};

type AddChargingStationFormProps = {
  onFormDirty: (isDirty: boolean) => void;
  closeDialog: () => void;
};

export const AddChargingStationForm: FC<AddChargingStationFormProps> = ({
  onFormDirty,
  closeDialog,
}) => {
  const [t] = useTranslation('chargingStations');
  const { showSnackbar } = useESSnackbar();
  const [isMapReady, setIsMapReady] = useState(false);
  const [addChargingStation, { isLoading }] = useAddChargingStationMutation();

  const methods = useForm({
    defaultValues: addChargingStationDefaultValues,
    mode: 'onTouched',
    resolver: yupResolver(addChargingStationValidationScheme),
  });
  const {
    formState: { isDirty, isValid, errors },
    control,
    setValue,
    trigger,
    handleSubmit,
  } = methods;

  const watchLongitude = useWatch({
    name: 'coordinates.longitude',
    control,
  });
  const watchLatitude = useWatch({
    name: 'coordinates.latitude',
    control,
  });

  const handleCoordsChange = (
    newCoords: AddChargingStationFormData['coordinates']
  ) => {
    setValue('coordinates.latitude', newCoords.latitude.slice(0, 10), {
      shouldValidate: true,
    });
    setValue('coordinates.longitude', newCoords.longitude.slice(0, 11), {
      shouldValidate: true,
    });
  };

  const onSubmit = useCallback(
    (data: AddChargingStationFormData) => {
      addChargingStation({
        identityKey: data.id,
        name: data.name,
        chargingHubUid: data.chargingHub.value,
        coordinates: data.coordinates,
        chargingStationVendor: data.vendor,
        chargingStationVendorModel: data.model,
      })
        .unwrap()
        .then(() => {
          showSnackbar(
            'success',
            'addChargingStationSuccess',
            'chargingStations'
          );
          closeDialog();
        })
        .catch((error) => {
          showSnackbar(
            'error',
            `chargingStationsApiErrors.${
              chargingStationsApiErrors[error.data?.errorCode]
            }`,
            'chargingStations'
          );
        });
    },
    [addChargingStation, showSnackbar, closeDialog]
  );

  useEffect(() => {
    onFormDirty(isDirty);
  }, [onFormDirty, isDirty]);

  return (
    <FormProvider {...methods}>
      <Box sx={{ pt: 4 }}>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value } }) => {
                    return (
                      <ESTextField
                        label={t('chargingStationGeneralDetailsLabels.id')}
                        type="text"
                        required
                        onChange={onChange}
                        onBlur={onBlur}
                        error={Boolean(errors['id'])}
                        helperText={
                          Boolean(errors['id']) &&
                          t(
                            `chargingStationGeneralDetailsErrors.${errors['id']?.message}`
                          )
                        }
                        value={value}
                        inputProps={{
                          'data-testid': 'chargingStationIdTextField',
                        }}
                      />
                    );
                  }}
                  name="id"
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value } }) => {
                    return (
                      <ESTextField
                        label={t('chargingStationGeneralDetailsLabels.name')}
                        required
                        type="text"
                        onChange={onChange}
                        onBlur={onBlur}
                        error={Boolean(errors['name'])}
                        helperText={
                          Boolean(errors['name']) &&
                          t(
                            `chargingStationGeneralDetailsErrors.${errors['name']?.message}`
                          )
                        }
                        value={value}
                        inputProps={{
                          'data-testid': 'chargingStationNameTextField',
                        }}
                      />
                    );
                  }}
                  name="name"
                />
              </Grid>
              <Grid item xs={12}>
                <AddChargingStationChargingHubSelect
                  closeDialog={closeDialog}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => {
                    return (
                      <ESTextField
                        label={t('chargingStationGeneralDetailsLabels.vendor')}
                        type="text"
                        onChange={onChange}
                        onBlur={onBlur}
                        error={Boolean(errors['vendor'])}
                        helperText={
                          Boolean(errors['vendor']) &&
                          t(
                            `chargingStationGeneralDetailsErrors.${errors['vendor']?.message}`
                          )
                        }
                        value={value}
                        inputProps={{
                          'data-testid': 'chargingStationVendorTextField',
                        }}
                      />
                    );
                  }}
                  name="vendor"
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => {
                    return (
                      <ESTextField
                        label={t('chargingStationGeneralDetailsLabels.model')}
                        type="text"
                        onChange={onChange}
                        onBlur={onBlur}
                        error={Boolean(errors['model'])}
                        helperText={
                          Boolean(errors['model']) &&
                          t(
                            `chargingStationGeneralDetailsErrors.${errors['model']?.message}`
                          )
                        }
                        value={value}
                        inputProps={{
                          'data-testid': 'chargingStationVendorModelTextField',
                        }}
                      />
                    );
                  }}
                  name="model"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Box height={370}>
                  <EditableMap
                    center={{
                      longitude: watchLongitude || '15',
                      latitude: watchLatitude || '52',
                    }}
                    zoom={
                      watchLatitude !==
                        addChargingStationDefaultValues.coordinates.latitude ||
                      watchLongitude !==
                        addChargingStationDefaultValues.coordinates.longitude
                        ? 13
                        : 3
                    }
                    onMapReady={() => setIsMapReady(true)}
                    onCoordsChange={handleCoordsChange}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  render={({ field: { value, onChange, onBlur } }) => {
                    return (
                      <ESTextField
                        value={value}
                        label={t(
                          'chargingStationGeneralDetailsLabels.longitude'
                        )}
                        type="text"
                        required
                        onChange={(event) => {
                          const isValid =
                            !isNaN(Number(event?.target.value)) ||
                            event?.target.value === '-';
                          if (event === undefined || isValid) {
                            trigger('coordinates.latitude');
                            onChange(event);
                          }
                        }}
                        onBlur={onBlur}
                        error={Boolean(errors['coordinates']?.longitude)}
                        helperText={
                          Boolean(errors['coordinates']?.longitude) &&
                          t(
                            `chargingStationGeneralDetailsErrors.${errors['coordinates']?.longitude?.message}`
                          )
                        }
                        inputProps={{
                          'data-testid': 'addStationLongitudeTextField',
                        }}
                      />
                    );
                  }}
                  name="coordinates.longitude"
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  render={({ field: { value, onChange, onBlur } }) => {
                    return (
                      <ESTextField
                        value={value}
                        label={t(
                          'chargingStationGeneralDetailsLabels.latitude'
                        )}
                        type="text"
                        required
                        onChange={(event) => {
                          const isValid =
                            !isNaN(Number(event?.target.value)) ||
                            event?.target.value === '-';
                          if (event === undefined || isValid) {
                            trigger('coordinates.longitude');
                            onChange(event);
                          }
                        }}
                        onBlur={onBlur}
                        error={Boolean(errors['coordinates']?.latitude)}
                        helperText={
                          Boolean(errors['coordinates']?.latitude) &&
                          t(
                            `chargingStationGeneralDetailsErrors.${errors['coordinates']?.latitude?.message}`
                          )
                        }
                        inputProps={{
                          'data-testid': 'addStationLatitudeTextField',
                        }}
                      />
                    );
                  }}
                  name="coordinates.latitude"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ESDialogActions sx={{ pt: 10, px: 0, width: '100%' }}>
          <ESButton
            loading={isLoading}
            disabled={!isValid || !isMapReady}
            onClick={handleSubmit(onSubmit)}
          >
            {t('addChargingStationDialogButton')}
          </ESButton>
        </ESDialogActions>
      </Box>
    </FormProvider>
  );
};
