import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Box, Button } from '@mui/material';
import { ProcessedAdHocTariffReportFormData } from './ad-hoc-tariff-report/ChargingHubAdHocTariffReportForm';
import { AdditionalCost } from './AdditionalCost';

interface AdditionalCostsProps {
  isDisabled: boolean;
}

export const AdditionalCosts: React.FC<AdditionalCostsProps> = ({
  isDisabled,
}) => {
  const { t } = useTranslation('chargingHubReports');
  const defaultAdditionalCost = {
    description: '',
    cost: '',
    isNew: true,
  };

  const { control } = useFormContext<ProcessedAdHocTariffReportFormData>();

  const {
    fields: additionalCosts,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'additionalCosts',
  });

  return (
    <Box>
      <Box
        sx={{
          position: 'static',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        {additionalCosts.map((additionalCost, index) => (
          <AdditionalCost
            key={additionalCost.id}
            additionalCostIndex={index}
            isDisabled={isDisabled}
            onRemove={remove}
            additionalCost={additionalCost}
          />
        ))}
      </Box>

      <Button
        color="primary"
        disabled={isDisabled}
        sx={{
          ml: 2,
          mt: 2,
          '&.MuiButton-textPrimary': {
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
        }}
        style={{ textTransform: 'none' }}
        onClick={() => {
          append(defaultAdditionalCost);
        }}
        variant="text"
        startIcon={<AddRoundedIcon />}
      >
        {t('addCostButton')}
      </Button>
    </Box>
  );
};
