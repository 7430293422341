import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from 'react-hook-form';
import { Grid, Typography } from '@mui/material';
import {
  ESDateTimePicker,
  ESVirtualizedAutocomplete,
  useESSnackbar,
} from '@energy-stacks/core/ui';
import {
  FacilityTypeModel,
  facilityTypeModel,
  processedReportsApiErrors,
  useCreateProcessedFacilityDamagesReportMutation,
  useEditProcessedFacilityDamagesReportMutation,
} from '@energy-stacks/obelis/feature-charging-hub-reports-data';
import { chargingHubsApi } from '@energy-stacks/obelis/feature-charging-hubs-data';
import { useAppDispatch } from '@energy-stacks/store';
import { ErrorApiResponse, formatDateTime } from '@energy-stacks/shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { ChargingHubReportContainer } from '../ChargingHubReportContainer';
import { chargingHubFacilityDamagesReportValidationSchema } from './chargingHubFacilityDamagesReportValidationSchema';
import { useFacilityDamagesRawReport } from './useFacilityDamagesRawReport';
import { useProcessedFacilityDamagesReport } from './useProcessedFacilityDamagesReport';
import { isValid } from 'date-fns';

type ProcessedFacilityDamagesReportFormData = {
  facilityType: FacilityTypeModel | null;
  emergenceDate: Date | null;
  forecastDate: Date | null;
  fixDate: Date | null;
};

export const ChargingHubFacilityDamagesReportForm = ({
  setIsFacilityDamagesFormDirty,
  setIsDisabled,
  activeStep,
  setActiveStep,
  isEditMode,
}: {
  setIsFacilityDamagesFormDirty: (isFacilityDamagesFormDirty: boolean) => void;
  setIsDisabled: (isDisabled: boolean) => void;
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
  isEditMode: boolean;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation('chargingHubReports');
  const [createProcessedFacilityDamagesReport, { isLoading: isCreating }] =
    useCreateProcessedFacilityDamagesReportMutation();
  const [editProcessedFacilityDamagesReport, { isLoading: isEditing }] =
    useEditProcessedFacilityDamagesReportMutation();
  const {
    data: rawReportData,
    isLoading: isFacilityDamagesRawReportLoading,
    error: rawDataError,
  } = useFacilityDamagesRawReport(isEditMode);
  const {
    data: processedReportData,
    isLoading: isFacilityDamagesProcessedReportLoading,
    error: processedDataError,
  } = useProcessedFacilityDamagesReport(isEditMode);
  const { id: chargingHubUid } = useParams<{
    id: string;
  }>();
  const dispatch = useAppDispatch();
  const { showSnackbar } = useESSnackbar();

  const reportData = isEditMode ? processedReportData : rawReportData;

  const defaultFacilityDamagesValues = useMemo(() => {
    return {
      facilityType: reportData?.facilityType || null,
      emergenceDate: reportData?.emergenceDate
        ? new Date(reportData.emergenceDate)
        : null,
      forecastDate: reportData?.forecastDate
        ? new Date(reportData.forecastDate)
        : null,
      fixDate: reportData?.fixDate ? new Date(reportData.fixDate) : null,
    };
  }, [reportData]);

  const formMethods = useForm<ProcessedFacilityDamagesReportFormData>({
    defaultValues: defaultFacilityDamagesValues,
    mode: 'onTouched',
    resolver: yupResolver(chargingHubFacilityDamagesReportValidationSchema),
  });

  const {
    control,
    handleSubmit,
    formState: { isValid: isFormValid, isDirty: isFormDirty, errors },
    reset: resetForm,
    trigger,
  } = formMethods;

  const emergenceDate = useWatch({
    control,
    name: 'emergenceDate',
  });
  const forecastDate = useWatch({
    control,
    name: 'forecastDate',
  });
  const fixDate = useWatch({
    control,
    name: 'fixDate',
  });

  const handleClose = useCallback(() => {
    resetForm();
    navigate(-1);
  }, [navigate, resetForm]);

  const isDisabled =
    isFacilityDamagesProcessedReportLoading ||
    isFacilityDamagesRawReportLoading ||
    isEditing ||
    isCreating;

  const submitChargingHubFacilityDamagesReportForm: SubmitHandler<ProcessedFacilityDamagesReportFormData> =
    useCallback(
      (data) => {
        const { facilityType, emergenceDate, forecastDate, fixDate } = data;
        const successMessage = isEditMode
          ? 'updateChargingHubFacilityDamagesReportSuccess'
          : 'createChargingHubFacilityDamagesReportSuccess';

        if (
          !facilityType ||
          !emergenceDate ||
          (!isEditMode && !chargingHubUid) ||
          (isEditMode && !reportData?.uid)
        ) {
          return;
        }

        const reportHandler = isEditMode
          ? editProcessedFacilityDamagesReport({
              uid: reportData?.uid as string,
              body: {
                facilityType,
                emergenceDate,
                forecastDate,
                fixDate,
              },
            })
          : createProcessedFacilityDamagesReport({
              chargingHubUid: chargingHubUid as string,
              uid: reportData?.uid,
              facilityType,
              emergenceDate,
              forecastDate,
              fixDate,
            });

        reportHandler
          .unwrap()
          .then(() => {
            showSnackbar('success', successMessage, 'chargingHubReports');
            dispatch(
              chargingHubsApi.util.invalidateTags(['ChargingHubReports'])
            );
            handleClose();
          })
          .catch((error) => {
            showSnackbar(
              'error',
              processedReportsApiErrors[error.data?.errorCode],
              'chargingHubReports'
            );
          });
      },
      [
        chargingHubUid,
        createProcessedFacilityDamagesReport,
        editProcessedFacilityDamagesReport,
        handleClose,
        isEditMode,
        dispatch,
        reportData?.uid,
        showSnackbar,
      ]
    );

  useEffect(() => {
    setIsFacilityDamagesFormDirty && setIsFacilityDamagesFormDirty(isFormDirty);
    setIsDisabled(isDisabled);
  }, [isFormDirty, setIsFacilityDamagesFormDirty, setIsDisabled, isDisabled]);

  useEffect(() => {
    resetForm(defaultFacilityDamagesValues);
  }, [defaultFacilityDamagesValues, resetForm]);

  useEffect(() => {
    if (rawDataError) {
      if ('status' in rawDataError && rawDataError.status === 404) {
        return;
      } else {
        showSnackbar(
          'error',
          'rawReportFetchErrorMessage',
          'chargingHubReports'
        );
      }
    }
  }, [rawDataError, showSnackbar]);

  useEffect(() => {
    if (processedDataError) {
      if ('data' in processedDataError) {
        const response = processedDataError.data as ErrorApiResponse;
        response
          ? showSnackbar(
              'error',
              processedReportsApiErrors[response.errorCode],
              'chargingHubReports'
            )
          : showSnackbar(
              'error',
              'processedReportFetchErrorMessage',
              'chargingHubReports'
            );
      } else {
        showSnackbar(
          'error',
          'processedReportFetchErrorMessage',
          'chargingHubReports'
        );
      }
      handleClose();
    }
  }, [processedDataError, showSnackbar, handleClose]);

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit(submitChargingHubFacilityDamagesReportForm)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'space-between',
        }}
      >
        <ChargingHubReportContainer
          title={t('facilityDamages')}
          isFormValid={isFormValid}
          isFormDirty={isFormDirty}
          isLoading={isEditing || isCreating}
          activeStep={activeStep ?? 1}
          setActiveStep={setActiveStep}
          isEditMode={isEditMode}
        >
          <Grid
            container
            spacing={8}
            sx={{
              padding: 4,
              alignItems: 'center',
            }}
          >
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight={500} color={'grey.600'}>
                {t('type') + ' *'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1">
                {reportData?.facilityType ? t(reportData?.facilityType) : '-'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="facilityType"
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <ESVirtualizedAutocomplete
                      label=""
                      placeholder={
                        reportData?.facilityType
                          ? t(reportData?.facilityType)
                          : '-'
                      }
                      required
                      disabled={isDisabled}
                      value={{
                        label: value ? t(value) : '',
                        value: value ?? '',
                      }}
                      options={facilityTypeModel.map((curr) => ({
                        label: t(curr),
                        value: curr,
                      }))}
                      noOptionsText={t(
                        'createChargingHubFacilityDamagesReportNoOptions'
                      )}
                      onBlur={onBlur}
                      blurOnSelect
                      onChange={(_, value) => {
                        if (Array.isArray(value)) {
                          return;
                        }
                        if (value) {
                          onChange(value.value ?? '');
                        }
                      }}
                      error={Boolean(errors['facilityType'])}
                      helperText={
                        Boolean(errors['facilityType']) &&
                        t(`${errors['facilityType']?.message}`)
                      }
                    />
                  );
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1" fontWeight={500} color={'grey.600'}>
                {t('emergenceDate') + ' *'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1">
                {reportData?.emergenceDate
                  ? formatDateTime(reportData?.emergenceDate)
                  : '-'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="emergenceDate"
                render={({ field: { onChange, onBlur, value } }) => (
                  <ESDateTimePicker
                    value={value ? value : null}
                    disabled={isDisabled}
                    maxDateTime={new Date()}
                    onChange={(value) => {
                      onChange(value);
                      if (isValid(forecastDate)) {
                        trigger('forecastDate');
                      }
                      if (isValid(fixDate)) {
                        trigger('fixDate');
                      }
                    }}
                    onBlur={onBlur}
                    onClose={() => trigger('emergenceDate')}
                    error={Boolean(errors['emergenceDate'])}
                    helperText={
                      Boolean(errors['emergenceDate']) &&
                      t(`${errors['emergenceDate']?.message}`)
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1" fontWeight={500} color={'grey.600'}>
                {t('forecastDate')}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1">
                {reportData?.forecastDate
                  ? formatDateTime(reportData?.forecastDate)
                  : '-'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="forecastDate"
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <ESDateTimePicker
                      value={value}
                      disabled={isDisabled}
                      minDateTime={emergenceDate || undefined}
                      actions={['clear']}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={Boolean(errors['forecastDate'])}
                      helperText={
                        Boolean(errors['forecastDate']) &&
                        t(`${errors['forecastDate']?.message}`)
                      }
                    />
                  );
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1" fontWeight={500} color={'grey.600'}>
                {t('fixDate')}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1">
                {reportData?.fixDate
                  ? formatDateTime(reportData?.fixDate)
                  : '-'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="fixDate"
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <ESDateTimePicker
                      value={value}
                      disabled={isDisabled}
                      minDateTime={emergenceDate || undefined}
                      actions={['clear']}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={Boolean(errors['fixDate'])}
                      helperText={
                        Boolean(errors['fixDate']) &&
                        t(`${errors['fixDate']?.message}`)
                      }
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </ChargingHubReportContainer>
      </form>
    </FormProvider>
  );
};
