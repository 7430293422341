import { additionalCostsReportDtoToModelNormalizer } from '../../normalizers/additionalCostsReportToPayload';
import { nonDiscriminatoryAccessReportContractualBasisDtoToModelMap } from '../../transformers/nonDiscriminatoryAccessReportContractualBasisMaps';
import { RawNonDiscriminatoryAccessReportDto } from '../rawNonDiscriminatoryAccessReportDto';
import { RawNonDiscriminatoryAccessReportModel } from '../rawNonDiscriminatoryAccessReportModel';

export const rawNonDiscriminatoryAccessReportNormalizer = (
  rawNonDiscriminatoryAccessReportDto: RawNonDiscriminatoryAccessReportDto
): RawNonDiscriminatoryAccessReportModel => {
  const {
    uid,
    monthYear,
    providerGroup,
    cost,
    additionalCosts,
    validFrom,
    validTo,
    contractualBasis,
    reasoningDifferentCosts,
  } = rawNonDiscriminatoryAccessReportDto;
  return {
    uid: uid ?? undefined,
    monthYear: monthYear ?? '',
    providerGroup: providerGroup ?? '',
    cost: cost ? String(cost) : '',
    additionalCosts: additionalCostsReportDtoToModelNormalizer(
      additionalCosts ?? []
    ),
    validFrom: validFrom ?? '',
    validTo: validTo ?? '',
    contractualBasis: contractualBasis
      ? nonDiscriminatoryAccessReportContractualBasisDtoToModelMap[
          contractualBasis
        ]
      : undefined,
    reasoningDifferentCosts: reasoningDifferentCosts ?? '',
  };
};
