export const chargingStationsReportsApiErrors: Record<string, string> = {
  RENEWAL_PROCESSED_CHARGING_POINT_REPORT_ALREADY_EXISTS: 'existWithUid',
  RENEWAL_PROCESSED_CHARGING_POINT_REPORT_FOR_HUB_POINT_AND_UID_EXISTS:
    'existWithRenewalId',
  RENEWAL_START_TIME_NEEDS_TO_BE_BEFORE_END_TIME: 'startTimeBefore',
  RENEWAL_PROCESSED_CHARGING_POINT_DOES_NOT_EXIST_FOR_PROVIDED_UID:
    'uidNotExist',
  MONTH_ON_REPORT_NOT_VALID: 'monthNotValid',
  CHARGING_POINT_DOES_NOT_EXIST: 'chargingPointNotFound',
  RATING_REPORT_FOR_CHARGING_POINT_ALREADY_EXISTS: 'ratingExist',
  CHARGING_POINT_NOT_ASSOCIATED_WITH_CHARGING_HUB: 'notAssociatedWithHub',
  IDENTITY_KEY_NOT_ASSOCIATED_WITH_CHARGING_POINT:
    'notAssociatedWithChargingPoint',
  REPORT_EMERGENCE_DATE_IS_LATER_THAN_FORECAST_DATE: 'laterThanForecast',
  CHARGING_POINT_DEFECTS_REPORT_ALREADY_EXISTS: 'defectAlreadyExist',
  REPORT_EMERGENCE_DATE_IS_LATER_THAN_FIX_DATE: 'laterThanFixDate',
  PROCESSED_CHARGING_POINT_DEFECT_UID_NUMBER_NOT_FOUND: 'defectNotFound',
  PROCESSED_RATING_REPORT_NOT_FOUND: 'processedRatingReportNotFound',
  OBELIS_EXTERNAL_API_REQUEST_FAILED_429: 'awsRateLimitExceeded',
  PROCESSED_DEFECT_REPORT_NOT_YET_CLOSED: 'previousDefectReportNotClosed',
};
